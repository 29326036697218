import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
    imgTitulo: {
        height: '100%',
        fill: theme.palette.primary.main,
        marginRight: theme.spacing(1)
    },
    containerLoginMain: {
        height: '40%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'end',
        gap: '20%',
    },
    containerTitle: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        height: '20%',
        width: '100%',
    },
    containerDesktopMain: {
        width: '100%',
        height: '100%',
        [theme.breakpoints.up("md")]: {
            justifyContent: 'center',
            alignItems: 'center',
        }
    },
    containerDesktopSub: {
        width: '100%',
        height: '100%',
        [theme.breakpoints.up("md")]: {
            width: '40%',
            height: '90%'
        }
    },
    containerLoginSub: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        padding: '5%',
        [theme.breakpoints.up("md")]: {
            padding: '0%'
        }
    },
    fontLogin: {
        fontSize: '2rem',
        color: theme.palette.primary.main,
        [theme.breakpoints.up("md")]: {
            fontSize: '3rem',
        }
    },
    fontLoginSubtitle: {
        fontSize: '0.8rem',
        color: theme.palette.grey[500],
        [theme.breakpoints.up("md")]: {
            fontSize: '1.5rem',
        }
    },
    containerForm: {
        height: '60%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'start',
        padding: '5%',
    },
    containerCheckbox: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        alignItems: 'center',
        "& label": {
            marginRight: '0px'
        },
        [theme.breakpoints.down("md")]: {
            marginTop: '5%',
        }
    },
    spanCheckbox: {
        [theme.breakpoints.up("md")]: {
            fontSize: '1.5rem'
        }
    },
    containerBotoes: {
        flexGrow: 1,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'end'
    },
    botaoAcessar: {
        padding: '3%',
        borderRadius: '8px',
        backgroundColor: theme.palette.primary.main,
        [theme.breakpoints.up("md")]: {
            padding: '0',
        }
    },
    botaoAcessarIcone: {
        maxHeight: '1.5rem',
        maxWidth: '1.5rem',
        marginRight: '1rem',
        fill: theme.palette.common.white,
        [theme.breakpoints.up("md")]: {
            maxHeight: '2rem',
            maxWidth: '2rem',
        }
    },
    botaoAcessarSpan: {
        [theme.breakpoints.up("md")]: {
            fontSize: '2rem',
        }
    }
}));

import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
    defaultContainer: {
        position: "relative",
        overflowY: "hidden",
        overflowX: "hidden",
    },
    containerMain: {
        height: '100%',
    },
    containerInputs: {
        height: '90%',
    },
    containerDataAtual: {
        width: "50%",
        height: "10%",
    },
    dataAtual: {
        borderRadius: '8px'
    },
    horaAtual: {
        borderRadius: '8px'
    },
    containerHoraAtual: {
        width: "50%",
        height: "10%",
    },
    dataNotaFiscal: {
        borderRadius: '8px'
    },
    containerDataNotaFiscal: {
        width: "97%",
        height: "10%",
    },
    numeroNotaFiscal: {
        borderRadius: '8px'
    },
    containerNumeroNotaFiscal: {
        width: "97%",
        height: "10%",
    },
    txtAutocompleteFornecedor: {
        borderRadius: '8px',
        width: "100%",
    },
    containerAutocompleteFornecedor: {
        width: "100%",
        height: "12%",
        padding: "2%",
        marginTop: '2%',
        alignSelf: "center",
    },
    containerMedicamentoLoteValidade: {
        width: "97%",
        height: "10%",
        paddingRight: "5px",
        paddingLeft: "5px",
        marginTop: '5px',
        margin: '5px',
        display: 'flex',
        flexDirection: 'row',
        alignSelf: "center",
        justifyContent: 'space-between'
    },
    containerAutocompleteMedicamento: {
        width: "90%",
        alignSelf: "start",
        height: '100%'
    },
    containerAddLoteValidade: {
        width: '9%',
        height: '100%',
        backgroundColor: theme.palette.primary.main,
        textAlign: "center",
        borderRadius: "8px",
    },
    btnLoteValidade: {
        color: theme.palette.primary.main,
        height: '100%',
    },
    containerBoxButtonFornecedores: {
        display: 'flex',
        width: '97%',
        height: '7%',
        marginTop: '5px',
        paddingLeft: '5px',
        flexDirection: 'row'
    },
    containerBoxButtonMedicamentos: {
        display: 'flex',
        width: '97%',
        height: '7%',
        marginTop: '5px',
        paddingLeft: '40px',
        flexDirection: 'row'
    },
    txtAutocompleteMedicamento: {
        fontSize: "5px",
        borderRadius: '8px',
        width: "100%",
        height: '100%'
    },
    containerBtnAddFornecedor: {
        width: '100%',
        backgroundColor: theme.palette.primary.main,
        textAlign: "center",
        borderRadius: "8px",
    },
    containerBtnAddMedicamento: {
        width: '100%',
        backgroundColor: theme.palette.primary.main,
        textAlign: "center",
        borderRadius: "8px"
    },
    btnAddFornecedor: {
        color: theme.palette.common.white,
        fontSize: "5px",
        height: "100%",
        textAlign: 'right'
    },
    btnAddMedicamento: {
        color: theme.palette.common.white,
        fontSize: "5px",
        height: "100%",
        textAlign: 'right'
    },
    containerButtons: {
        width: '96%',
        height: '4%',
        marginLeft: '5px',
        display: 'flex',
        padding: '10px',
        flexDirection: 'row',
        justifyContent: 'space-between'
    },
    containerBtnSalvar: {
        width: '45%',
        backgroundColor: theme.palette.primary.main,
        borderRadius: '8px',
        textAlign: "center"
    },
    btnSalvarTxt: {
        fontSize: '5px',
        color: theme.palette.common.white,
        alignItems: "center",
        height: '100%'
    },
    containerBtnAutorizarSalvar: {
        width: '95%',
        backgroundColor: theme.palette.primary.main,
        borderRadius: '8px',
        alignSelf: "center",
        position: 'absolute',
        bottom: 0,
        marginBottom: '2%'
    },
    btnAutorizarSalvarTxt: {
        color: theme.palette.common.white,
        height: '100%',
        width: '100%'
    },

    buttonStyle: {
        flexGrow: 1,
        minWidth: '47%',
        borderRadius: '10px',
        height: '4rem',
        alignContent: 'center'
    },

    txtSpanButtons: {
        padding: "2px",
        margin: '2px',
        alignSelf: "center",
    },
    containerGridLoteValidade: {
        borderRadius: '10px',
        width: '100%',
        alignContent: 'center',
        padding: '4px',
        height: '51%',
        overflow: 'auto'
    },
    estiloGridContainer: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
    },
    estiloTxtGridColuna: {
        fontWeight: 'bold',
        fontSize: '5px',
        textAlign: 'center',
        backgroundColor: theme.palette.grey[100],
        borderRadius: '10px',
    },
    estiloTxtGrid: {
        fontSize: '5px',
        textAlign: 'center'
    },
    iconeBotaoDeAcao: {
        color: theme.palette.common.white,
        height: '5px',
        width: '5px',
    },
    textoBotaoMargem: {
        marginLeft: '12px'
    },

    formPadding: {
        flexGrow: 1,
        padding: '1rem 0.5rem'
    },

    noPadding: {
        padding: 0
    },

    wizardRowContainer: {
        justifyContent: 'space-between'
    },

    wizardButtonContainer: {
        justifyContent: 'center',
        alignItems: 'center'
    },

    wizardButtonStyle: {
        fontSize: '1rem',
        height: '2.5rem',
        width: '2.5rem',
        backgroundColor: theme.palette.common.black,
        color: theme.palette.common.white,
        borderRadius: '500px'
    },

    formContainer: {
        flexGrow: 1,
        maxHeight: 'calc(100vh - 250px)'
    },

    formContainerVisualizacao: {
        flexGrow: 1,
        maxHeight: 'calc(100vh - 200px)'
    },

    buttonContainer: {
        height: '4rem',
        gap: '5%',
        [theme.breakpoints.up('sm')]: {
            width: '95%',
            alignSelf: 'center',
            marginBottom: '2%'
        }
    },

    buttonCadastroAvulsoContainer: {
        height: '100%',
        padding: theme.spacing(2, 1),
        flex: 0
    },

    iconHeaderPage: {
        height: '1rem',
        color: theme.palette.common.white,
        cursor: 'pointer',
        marginRight: '0.72rem',
        alignSelf: 'center'
    },

    notaFiscalHeader: {
        fontSize: '25px',
        fontWeight: 'bold',
        alignSelf: 'center',
        paddingTop: '4%',
        color: theme.palette.primary.main
    },

    notaFiscalSubTitle: {
        fontWeight: 'bold',
        alignSelf: 'center'
    },

    dataContainer: {
        width: '100%',
        flexDirection: 'row',
        padding: '4% 2% 0 2%',
        display: 'flex',
        gap: '2%'
    },

    notaFiscalContainer: {
        width: '100%',
        flexDirection: 'row',
        padding: '4% 2% 0 2%',
        display: 'flex',
        gap: '2%'
    },

    medicamentoHeader: {
        fontSize: '25px',
        fontWeight: 'bold',
        alignSelf: 'center',
        padding: '5%',
        color: theme.palette.primary.main
    },

    medicamentoSubTitle: {
        fontWeight: 'bold',
        alignSelf: 'center',
        padding: '2%'
    },

    whiteButton: {
        color: theme.palette.primary.main,
        backgroundColor: theme.palette.common.white,
        border: `2px solid ${theme.palette.primary.main}`
    },

    darkButton: {
        color: theme.palette.common.white,
        backgroundColor: theme.palette.primary.main,
    },

    buttonLabel: {
        fontSize: '1rem',
        [theme.breakpoints.up('md')]: {
            fontSize: '1.7rem'
        },
    },

    whiteLabel: {
        color: theme.palette.common.white,
        fill: theme.palette.common.white,
    },

    darkLabel: {
        color: theme.palette.primary.main,
        fill: theme.palette.primary.main,
    },

    iconSize: {
        width: '1.3rem',
        [theme.breakpoints.up('md')]: {
            width: '2rem'
        }
    },

    titleContainer: {
        color: theme.palette.common.black,
        alignItems: 'center'
    },

    titleStyle: {
        width: 'fit-content'
    },

    inputContainer: {
        gap: '4%'
    },

    inputStyle: {
        borderRadius: '10px',
        height: '3.5rem'
    },

    inputMedStyle: {
        borderRadius: '10px',
        height: '3.5rem'
    },

    autocompleteContainerStyle: {
        height: '3.5rem'
    },

    innerFormContainer: {
        gap: '1.7rem',
        padding: '1rem 0',
        marginTop: '2%',
        [theme.breakpoints.up('sm')]: {
            width: '95%',
            alignSelf: 'center'
        }
    },

    innerFormContainerMedicamento: {
        overflowY: 'auto',
        gap: '5%',
        padding: '1rem 0',
        [theme.breakpoints.up('sm')]: {
            width: '100%',
            alignSelf: 'center'
        }
    },

    cardMedicamentoItem: {
        justifyContent: 'center',
    },

    cardContainer: {
        padding: '0 0.25rem'
    },

    infoContainer: {
        margin: '0.3rem 0.5rem',
        justifyContent: 'space-between',
        flexGrow: 1
    },

    labelFont: {
        fontSize: '0.6rem',
        color: theme.palette.grey[400],
        marginBottom: '-0.25rem',
        [theme.breakpoints.up('sm')]: {
            fontSize: '0.75rem',
        }
    },

    contentFont: {
        fontSize: '0.75rem',
        color: theme.palette.common.black,
        [theme.breakpoints.up('sm')]: {
            fontSize: '0.8rem',
        }
    },

    formHeight: {
        height: '92%',
        paddingTop: '2%',
        [theme.breakpoints.down('sm')]: {
            padding: '4%'
        }
    },

    optionsButtonContainer: {
        display: 'flex',
        width: 'fit-content',
        alignItems: 'center',
        paddingRight: '1rem',
        paddingLeft: '1rem',
        alignSelf: 'center'
    },

    optionsButtonStyle: {
        padding: '0.15rem',
        color: theme.palette.common.black,
    },

    infoContainerCompraAvulsa: {
        padding: '0 0.25rem',
        height: '30%',
    },

    itensDiv: {
        marginTop: "4%",
        justifyContent: 'space-between'
    },

    addButtonStyle: {
        height: 'fit-content',
        width: '4rem',
        borderRadius: '10px'
    },

    addButtonIcon: {
        height: '3vh',
        width: '3vh',
        margin: '0.3vh 0'
    },

    divCardMed: {
        height: '100%',
        alignItems: 'center',
    },

    scrollMeds: {
        overflowY: 'auto',
        width: '100%',
        alignSelf: 'center',
        padding: theme.spacing(1),
        scrollBehavior: 'smooth',
    },

    formHeightCadastroAvulso: {
        height: '100%',
    },

    wizardHeight: {
        marginTop: '2%',
        height: '15%'
    },

    medsHeight: {
        height: '0',
        flex: '1 1 auto',
        overflowY: 'auto'
    },

    inputContainerAlign: {
        gap: '3%',
        width: '90%',
        alignSelf: 'center'
    },

    padding5: {
        padding: '5%'
    },

    cardMedicamentoAlign: {
        width: '100%',
        alignSelf: 'center'
    },

    containerButtonAdd: {
        display: 'flex',
        justifyContent: 'flex-end',
        marginTop: 10
    },

    widthInput: {
        width: '30%',
    },

    widthInputItens: {
        width: '20%',
    },
    cardRightButton: {
        width: "50px",
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2),
        marginRight: theme.spacing(0),
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        "& svg": {
            margin: 0,
            width: "22px",
            height: "22px",
            fill: theme.palette.text.primary,
        },
        [theme.breakpoints.up('sm')]: {
            borderLeft: `1px solid ${theme.palette.text.primary}`
        }
    },

    compraAvulsaMedicamentosForm: {
        height: '100%'
    },

    buttonCadastroAlign: {
        width: '100%',
        height: '4rem',
    }

}));
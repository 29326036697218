import { yupResolver } from '@hookform/resolvers/yup';
import { Button } from "@material-ui/core";
import classNames from "classnames";
import moment from "moment";
import { useEffect } from "react";
import { Controller, useForm } from "react-hook-form";
import * as yup from "yup";
import { useToastSaurus } from "../../../../../../services/app";
import { TextFieldSaurus } from "../../../../../components/controles/inputs";
import { useThemeQueries } from "../../../../../theme";
import { InventarioEditarLoteMedicamentoProp, InventarioLote } from "../../models/inventario.models";
import { useStyles } from "../inventario-lote.styles";

export const ErroInitialValue = {
  erroLote: false,
  erroDataValidade: false,
  erroQuantidade: false
}

export const InventarioLoteForm = (({
  salvarEdicaoLote,
  loteEdicao }: InventarioEditarLoteMedicamentoProp) => {

  const { showToast } = useToastSaurus();
  const styles = useStyles();
  const schema = yup
    .object()
    .shape({
      lote: yup.string()
        .required("Lote é obrigatório!"),
      dataValidade: yup.string()
        .required("Data de Validade é obrigatória!"),
      quantidade: yup.number()
        .required("Quantidade é obrigatória!")
    });

  const {
    control,
    formState,
    clearErrors,
    handleSubmit,
    reset,
    getValues,
  } = useForm<InventarioLote>({
    criteriaMode: 'all',
    mode: 'all',
    resolver: yupResolver(schema)
  });

  const { errors } = formState;

  useEffect(() => {
    reset({ ...loteEdicao })
  }, [loteEdicao, reset]);

  function verificarPeriodoPermitido(lote: InventarioLote): boolean {

    if (lote?.dataValidade) {

      if (moment(lote?.dataFabricacao).isAfter(moment(lote?.dataValidade), 'day')) {
        showToast('error', 'A Data de Validade não pode ser menor que a Data de Fabricação!', 5000, 'top-center')
        return false;
      }

    }

    return true;

  }

  const salvar = async (model: InventarioLote) => {
    var formIsValid = await schema.isValid(model);

    if (!formIsValid) {
      return showToast("error", "Preencha os campos obrigatórios!", 5000, 'top-center');
    }


    if (!verificarPeriodoPermitido(getValues()))
      return;

    let validadeFormatada = moment(model?.dataValidade).format('DD/MM/YYYY');
    let fabricacaoFormatada = model?.dataFabricacao ? moment(model?.dataFabricacao).format('DD/MM/YYYY') : "";

    salvarEdicaoLote(
      {
        ...model,
        dataValidade: model?.dataValidade,
        dataFabricacao: model?.dataFabricacao,
        dataValidadeFormatada: validadeFormatada,
        dataFabricacaoFormatada: fabricacaoFormatada
      },
      loteEdicao.lote,
    );
    clearErrors();
  }

  const { md } = useThemeQueries();

  return (
    <form onSubmit={(e) => {
      e.preventDefault();
      handleSubmit(salvar)()
    }} className='h-100 flex-column'>

      <div className={classNames(styles.containerInput, 'flex-grow1')}>

        <div className={`flex-column w-100 ${styles.loteFormContainer}`}>

          <div className={`flex-row w-100 ${styles.formContainer}`}>
            <Controller
              control={control}
              name='lote'
              render={({ field }) => (
                <TextFieldSaurus
                  required
                  className={`w-100 ${styles.inputStyle}`}
                  tipo='TEXTO'
                  labelCustom='Lote'
                  variant='outlined'
                  size='small'
                  error={Boolean(errors.lote && !field.value)}
                  helperText={!md &&
                    (errors.lote && !field.value)
                    ? errors.lote?.message
                    : undefined
                  }
                  {...field}
                  min={undefined}
                  max={undefined}
                />
              )}
            />

            <Controller
              name='quantidade'
              control={control}
              render={({ field }) => (
                <TextFieldSaurus
                  required
                  className={`w-100 ${styles.inputStyle}`}
                  tipo='NUMERO'
                  labelCustom='Quantidade'
                  variant="outlined"
                  size="small"
                  error={Boolean(errors.quantidade && !field.value)}
                  helperText={!md &&
                    (errors.quantidade && !field.value)
                    ? errors.quantidade?.message
                    : undefined
                  }
                  {...field}
                  min={undefined}
                  max={undefined}
                  maxLength={5}
                />
              )}
            />

          </div>

          <div className={`flex-row w-100 ${styles.formContainer}`}>
            <Controller
              name="dataFabricacao"
              control={control}
              render={({ field }) => (
                <TextFieldSaurus
                  className={`w-100 ${styles.inputStyle}`}
                  tipo='DATA'
                  variant='outlined'
                  labelCustom='Data de Fabricação'
                  showStartAdornment={true}
                  allowSubmit={true}
                  size="small"
                  fullWidth={true}
                  {...field}
                  value={field.value ? new Date(field.value || '').toISOString()?.slice(0, 10) : ''}
                  onBlur={(e: any) => {
                    verificarPeriodoPermitido(getValues());
                    field.onBlur();
                  }}
                />
              )}
            />

            <Controller
              control={control}
              name='dataValidade'
              render={({ field }) => (
                <TextFieldSaurus
                  required
                  className={`w-100 ${styles.inputStyle}`}
                  tipo='DATA'
                  labelCustom='Data de Validade'
                  variant='outlined'
                  size="small"
                  showStartAdornment={true}
                  allowSubmit={true}
                  fullWidth={true}
                  error={Boolean(errors.dataValidade && !field.value)}
                  helperText={!md &&
                    (errors.dataValidade && !field.value)
                    ? errors.dataValidade?.message
                    : undefined
                  }
                  {...field}
                  value={field.value ? new Date(field.value).toISOString()?.slice(0, 10) : ''}
                  min={undefined}
                  max={undefined}
                />
              )}
            />
          </div>

        </div>
      </div>

      <div className={`flex-row w-100 ${styles.containerAdicionar}`}>
        <div className={
          classNames(
            styles.buttonStyle,
            styles.darkButton)}>

          <Button type='submit' variant="text" className="h-100 w-100">
            <div className={`flex-row ${styles.buttonLabel} ${styles.whiteLabel}`}>
              Concluir
            </div>
          </Button>

        </div>

      </div>

    </form>
  );
});
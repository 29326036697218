import { Typography } from "@material-ui/core";
import { Dispatch, SetStateAction } from "react";
import { DefaultCard } from "../../../../../components/cards/components";
import { ButtonModalHeader } from "../../../../../components/controles/buttons/button-modal-header";
import { TextFieldSaurus } from "../../../../../components/controles/inputs";
import { DefaultModal } from "../../../../../components/default-modal";
import { VoltarIcon } from "../../../../../components/icons";
import { ModalHeader } from "../../../../../components/modal-header";
import { EnvioInventarioLote } from "../envio-inventario-list/envio-inventario-models";
import { useStyles } from "./envio-inventario-modal-style";

interface EnvioInventarioModalProps {
  lotes: EnvioInventarioLote[],
  medicamento?: string,
  openned?: boolean;
  setModalOpen: Dispatch<SetStateAction<boolean>>;
}

export const EnvioInventarioModal = (props: EnvioInventarioModalProps) => {
  const styles = useStyles();
  const fecharModal = () => props.setModalOpen(false);

  return (
    <DefaultModal
      minWidth="400px"
      open={props.openned}
      variant={"temporary"}
      anchor="right"
    >
      <ModalHeader
        title={'Medicamento'}
        leftArea={
          <ButtonModalHeader
            tooltip="Voltar"
            icon={<VoltarIcon tipo="MODAL_HEADER" />}
            onClick={() => fecharModal()}
          />
        }
      />
      <div className={`flex-row w-100 ${styles.medicamentoInfoContainer}`}>
        <div className={`flex-column h-100 ${styles.medicamentoInfoStyle}`}>
          <TextFieldSaurus
            value={props.medicamento}
            className={styles.inputStyle}
            disabled={true}
          />
        </div>
      </div>
      <div className={`flex-column h-100 w-100 ${styles.formPadding}`}>

        <div className={`flex-column w-100 ${styles.titleContainer}`}>
          <Typography className={`w-100 ${styles.titleStyle}`}>
            Lote e Validade
          </Typography>
          <div className={`w-100 ${styles.breakLine}`} />
        </div>

        <div className={`flex-column h-100 w-100 ${styles.innerFormContainerMedicamento}`}>
          {
            props.lotes?.map((lote: any, index) => (
              <div key={index} className={`flex-column w-100 ${styles.cardMedicamentoItem}`}>
                <DefaultCard>
                  <div className={`flex-row h-100 w-100 ${styles.cardContainerLote}`}>

                    <div className={`flex-row w-100 ${styles.infoContainer}`}>
                      <div className="flex-column">
                        <Typography className={styles.labelFont}>
                          Lote
                        </Typography>
                        <Typography className={styles.contentFont}>
                          {lote.lote !== '' ? lote.lote : '--'}
                        </Typography>
                      </div>
                      <div className="flex-column">
                        <Typography className={styles.labelFont}>
                          Validade
                        </Typography>
                        <Typography className={styles.contentFont}>
                          {lote?.dataValidadeFormatada ?? ''}
                        </Typography>
                      </div>
                      <div className="flex-column">
                        <Typography className={styles.labelFont}>
                          Quantidade
                        </Typography>
                        <Typography className={styles.contentFont}>
                          {lote.quantidade ?? 0}
                        </Typography>
                      </div>
                    </div>
                  </div>
                </DefaultCard>
              </div>
            ))
          }
        </div>
      </div>
    </DefaultModal>
  );
};

import { yupResolver } from '@hookform/resolvers/yup';
import { Button, Typography } from "@material-ui/core";
import classNames from "classnames";
import moment from "moment";
import { Controller, useForm } from "react-hook-form";
import * as yup from "yup";
import { useToastSaurus } from "../../../../../../services/app";
import { TextFieldSaurus } from "../../../../../components/controles/inputs";
import { useThemeQueries } from "../../../../../theme";
import { InventarioAdicionarLoteMedicamentoProp, InventarioLote } from "../../models/inventario.models";
import { useStyles } from "../inventario-lote.styles";

export const ErroInitialValue = {
  erroLote: false,
  erroDataValidade: false,
  erroQuantidade: false
}

export const InventarioLoteAdicionar = (({
  medicamentonome,
  adicionarLote }: InventarioAdicionarLoteMedicamentoProp) => {

  const { showToast } = useToastSaurus();
  const styles = useStyles();

  const schema = yup
    .object()
    .shape({
      lote: yup.string()
        .required("Lote é obrigatório!"),
      dataValidade: yup.string()
        .required("Data de Validade é obrigatória!"),
      quantidade: yup.number()
        .required("Quantidade é obrigatória!")
    });

  const {
    control,
    formState,
    clearErrors,
    handleSubmit,
    getValues,
  } = useForm<InventarioLote>({
    criteriaMode: 'all',
    mode: 'all',
    resolver: yupResolver(schema)
  });

  const { errors } = formState;

  function verificarPeriodoPermitido(lote: InventarioLote): boolean {

    if (lote?.dataValidade) {

      if (moment(lote?.dataFabricacao).isAfter(moment(lote?.dataValidade), 'day')) {
        showToast('error', 'A Data de Validade não pode ser menor que a Data de Fabricação!', 5000, 'top-center')
        return false;
      }

    }

    return true;

  }

  const adicionar = async (model: InventarioLote) => {
    var formIsValid = await schema.isValid(model);

    if (!formIsValid)
      return showToast("error", "Preencha os campos obrigatórios!", 5000, 'top-center');

    if (!verificarPeriodoPermitido(getValues()))
      return;

    let validadeFormatada = moment(model.dataValidade).format('DD/MM/YYYY');
    let fabricacaoFormatada = model.dataFabricacao ? moment(model.dataFabricacao).format('DD/MM/YYYY') : "";

    adicionarLote({
      ...model,
      dataValidade: model.dataValidade,
      dataFabricacao: model.dataFabricacao,
      dataValidadeFormatada: validadeFormatada,
      dataFabricacaoFormatada: fabricacaoFormatada
    });

    clearErrors("lote");
    clearErrors("quantidade");
    clearErrors("dataValidade");

  }

  const { md } = useThemeQueries();



  return (
    <>

      <div className={styles.containerMedicamento} >

        <div className='flex-row w-100'>
          <Typography className={styles.labelMedicamentoFont}>
            Medicamento:
          </Typography>
          <Typography className={styles.contentFontMedicamento}>
            {medicamentonome}
          </Typography>
        </div>

      </div>

      <form className={styles.containerInput} onSubmit={(e) => {
        e.preventDefault();
        handleSubmit(adicionar)()
      }}>

        <div className={`flex-column w-100 ${styles.loteFormContainer}`}>

          <div className={`flex-row w-100 ${styles.formContainer}`}>
            <Controller
              name='lote'
              control={control}
              render={({ field }) => (
                <TextFieldSaurus
                  className={`w-100 ${styles.inputStyle}`}
                  tipo='TEXTO'
                  labelCustom='Lote'
                  variant='outlined'
                  size='medium'
                  error={Boolean(errors.lote && !field.value)}
                  helperText={!md &&
                    (errors.lote && !field.value)
                    ? errors.lote?.message
                    : undefined
                  }
                  {...field}
                  min={undefined}
                  max={undefined}
                />
              )}
            />

            <Controller
              control={control}
              name='quantidade'
              render={({ field }) => (
                <TextFieldSaurus
                  className={`h-100 w-100 ${styles.inputStyle}`}
                  tipo='NUMERO'
                  labelCustom='Quantidade'
                  variant="outlined"
                  size="medium"
                  error={Boolean(errors.quantidade)}
                  helperText={!md &&
                    (errors.quantidade)
                    ? errors.quantidade?.message
                    : undefined
                  }
                  {...field}
                  min={undefined}
                  max={undefined}
                  maxLength={5}
                />
              )}
            />

          </div>

          <div className={`flex-row w-100 ${styles.formContainer}`}>
            <Controller
              name="dataFabricacao"
              control={control}
              render={({ field }) => (
                <TextFieldSaurus
                  className={`w-100 ${styles.inputStyle}`}
                  tipo='DATA'
                  variant='outlined'
                  labelCustom='Data de Fabricação'
                  showStartAdornment={true}
                  allowSubmit={true}
                  size="medium"
                  fullWidth={true}
                  {...field}
                  onBlur={(e: any) => {
                    verificarPeriodoPermitido(getValues());
                    field.onBlur();
                  }}
                />
              )}
            />

            <Controller
              name='dataValidade'
              control={control}
              render={({ field }) => (
                <TextFieldSaurus
                  className={`w-100 ${styles.inputStyle}`}
                  tipo='DATA'
                  labelCustom='Data de Validade'
                  variant='outlined'
                  size="medium"
                  showStartAdornment={true}
                  allowSubmit={true}
                  fullWidth={true}
                  error={Boolean(errors.dataValidade)}
                  helperText={!md &&
                    (errors.dataValidade)
                    ? errors.dataValidade?.message
                    : undefined
                  }
                  {...field}
                  min={undefined}
                  max={undefined}
                />
              )}
            />
          </div>

          <div className={`flex-row w-100 ${styles.containerAdicionar}`}>

            <div className={classNames(styles.buttonStyle, styles.darkButton)}>
              <Button type='submit' variant="text" className="h-100 w-100">
                <div className={`flex-row ${styles.buttonLabel} ${styles.whiteLabel}`}>
                  Adicionar
                </div>
              </Button>

            </div>

          </div>

        </div>
      </form>

    </>
  );
});
import { Box, TextField, Typography } from '@material-ui/core';
// import SearchIcon from '@material-ui/icons/Search';
// import CloseIcon from '@material-ui/icons/Close';
// import { AccessAlarm, ThreeDRotation } from '@material-ui/icons';

import { TextFieldSaurusProps } from './text-field-saurus-props';
import { useStyles } from './text-field-saurus-styles';

import SearchIcon from '@material-ui/icons/Search';
import {
  ChangeEvent,
  FocusEvent,
  ReactElement,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import { useThemeQueries } from '../../..';
import { toDecimalString } from '../../../../utils/to-decimal';
import { CadeadoIcon, CadeadoSenhaIcon, EmailIcon } from '../../icons';
import { ButtonIcon } from '../buttons/button-icon';
import { mask } from './text-field-saurus-masks';
import { validador } from './text-field-saurus-validator';

export const TextFieldSaurus = ({
  onBlur,
  onChange,
  onSearch,
  value,
  adornmentColor,
  searchable,
  showStartAdornment,
  allowSubmit,
  tipo,
  casasDecimais,
  minDate,
  maxDate,
  min,
  max,
  step,
  showPasswordVisibilityButton,
  manterMascara,
  readOnly,
  register,
  endAdornmentButton,
  autoComplete,
  labelCustom,
  maxLength,
  ...txtProps
}: TextFieldSaurusProps) => {
  const { theme } = useThemeQueries();
  const classes = useStyles(theme);
  const [type, setType] = useState('');
  const [endAdornment, setEndAdornment] = useState<ReactElement | null>(null);
  const [startAdornment, setStartAdornment] = useState<ReactElement | null>(
    null,
  );
  const [invalido, setInvalido] = useState(false);
  const [showPassword, setShowpassword] = useState(false);
  const refValue = useRef('');

  const putMask = useCallback(
    (value: string) => {
      if (!value && tipo !== 'NUMERO')
        value = '';
      //CORRECAO DE QUANDO VEM EXTERNO PARA PREENCHIMENTO (DE DECIMAL)
      if (typeof value === 'number')
        value = toDecimalString(value, tipo === 'DECIMAL' ? casasDecimais : 0);

      return mask(value, tipo, casasDecimais, min, max);
    },
    [tipo, casasDecimais, min, max],
  );
  const validateField = useCallback(
    (value: string) => {
      return validador(value, tipo);
    },
    [tipo],
  );

  const onSearchWrapper = useCallback(() => {
    if (onSearch !== undefined) {
      onSearch(refValue.current);
    }
  }, [refValue, onSearch]);

  const getInputMode = ():
    | 'none'
    | 'text'
    | 'tel'
    | 'url'
    | 'email'
    | 'numeric'
    | 'decimal'
    | 'search'
    | undefined => {
    switch (tipo) {
      case 'CNPJ':
      case 'CNPJ_CPF':
      case 'CPF':
      case 'NUMERO':
      case 'CEP':
        return 'numeric';
      case 'NUMERO_RANGE':
        return 'numeric';
      case "EMAIL":
        return "email";
      case 'COR':
        return 'none';
      case 'PASSWORD':
        return 'email';
      case 'NUMERO_SLIDER':
        return 'none';
      case 'DATA':
        return 'text';
      case 'DATA_HORA':
        return 'text';
      case 'HORA':
        return 'text';
      case 'TELEFONE':
        return 'tel';
      case 'DECIMAL':
        return 'decimal';
      case 'URL':
        return 'url';
      default:
        return 'text';
    }
  };

  const selectInputType = useCallback(() => {
    switch (tipo) {
      case 'NUMERO':
        return 'text';
      case 'NUMERO_RANGE':
        return 'number';
      case 'EMAIL':
        return 'email';
      case 'COR':
        return 'color';
      case 'PASSWORD':
        return 'password';
      case 'NUMERO_SLIDER':
        return 'range';
      case 'DATA':
        return 'date';
      case 'DATA_HORA':
        return 'datetime-local';
      case 'HORA':
        return 'time';
      default:
        return 'text';
    }
  }, [tipo]);

  useEffect(() => {
    //controle do tipo html do input
    let tipo = 'text';
    //quando eu falo para mostrar a senha ela não seleciona o tipo do input e dica como text
    if (!showPassword) {
      tipo = selectInputType();
    }
    setType(tipo);
  }, [tipo, showPassword, selectInputType]);

  useEffect(() => {
    let endAdornment = null;

    //vejo se está inválido
    if (invalido) {
      // endAdornment = <CloseIcon style={{ color: 'red' }} />;
    } else {
      //vejo se está desabilitado
      if ((txtProps.disabled || readOnly) && !searchable) {
        endAdornment = (
          <CadeadoIcon tipo="INPUT" fill={theme.palette.text.disabled} />
        );
      } else {
        //vejo se é pesquisável
        if (searchable) {
          endAdornment = (
            <SearchIcon
              style={{
                color: adornmentColor || theme.palette.text.primary,
                cursor: 'pointer',
              }}
              onClick={() => {
                if (!(txtProps.disabled || readOnly)) onSearchWrapper();
              }}
            />
          );
        }
        //vejo se é para mostrar botao de mostrar/ocultar senha
        if (showPasswordVisibilityButton) {
          endAdornment = (
            <Box ml={1}>
              <ButtonIcon
                icon={
                  showPassword ? (
                    <Typography variant="caption" color="primary">
                      <b>Ocultar</b>
                    </Typography>
                  ) : (
                    <Typography variant="caption" color="primary">
                      <b>Mostrar</b>
                    </Typography>
                  )
                }
                onClick={() => {
                  setShowpassword((prev) => !prev);
                }}
              />
            </Box>
          );
        }
      }
    }
    setEndAdornment(endAdornment);
  }, [
    txtProps.disabled,
    searchable,
    adornmentColor,
    invalido,
    showPassword,
    onSearchWrapper,
    theme.palette.text.primary,
    showPasswordVisibilityButton,
    theme.palette.text.disabled,
    readOnly,
  ]);

  useEffect(() => {
    let startAdornment = null;
    if (showStartAdornment) {
      switch (tipo) {
        case 'PASSWORD':
          startAdornment = (
            <Box mr={1}>
              <CadeadoSenhaIcon tipo="INPUT" />
            </Box>
          );
          break;
        case 'EMAIL':
          startAdornment = (
            <Box mr={1}>
              <EmailIcon tipo="INPUT" />
            </Box>
          );
          break;
        default:
          startAdornment = null;
          break;
      }
    }

    setStartAdornment(startAdornment);
  }, [tipo, showStartAdornment]);

  useEffect(() => {
    //para o on search pegar o valor
    if (searchable) refValue.current = value as string;
  }, [searchable, value]);

  //caso queira botar algo no on change ta aqui
  const onChangeWrapper = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      if (manterMascara) {
        event.currentTarget.value = putMask(event.currentTarget.value);
      }
      if (onChange !== undefined) {
        onChange(event);
      }
    },
    [manterMascara, onChange, putMask],
  );

  const onBlurWrapper = useCallback(
    (event: FocusEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      let valid = validateField(event.currentTarget.value);
      setInvalido(!valid);

      if (onBlur !== undefined) {
        if (manterMascara)
          event.currentTarget.value = putMask(event.currentTarget.value);
        onBlur(event);
      }
    },
    [validateField, onBlur, putMask, manterMascara],
  );

  const onKeyDownWrapper = useCallback(
    (event: any) => {
      if (event.keyCode === 13) {
        if (searchable) {
          onSearchWrapper();
        } else if (!allowSubmit) {
          const form = event.target.form;
          if (form) {
            const focusableElements =
              'a:not([disabled]), button:not([disabled]), input:not([disabled]), [tabindex]:not([disabled]):not([tabindex="-1"])';
            const index = Array.prototype.indexOf.call(
              form?.querySelectorAll(focusableElements),
              event.target,
            );
            if (index === form?.querySelectorAll(focusableElements)) {
              form.querySelectorAll(focusableElements)[0].focus();
            } else {
              form.querySelectorAll(focusableElements)[index + 1].focus();
            }
          }
        }
        if (!allowSubmit) {
          event.preventDefault();
        }
      }
    },
    [searchable, allowSubmit, onSearchWrapper],
  );

  return (
    <>
      <TextField
        InputLabelProps={{ shrink: true }}
        label={
          <span >
            {labelCustom}
          </span>
        }
        className={
          tipo === 'COR'
            ? `${classes.textFieldSaurusCor}`
            : `${classes.textFieldSaurus}`
        }
        inputProps={{
          min: minDate !== "" ? minDate : min,
          max: maxDate !== "" ? maxDate : max,
          step: step,
          readOnly: readOnly,
          inputMode: getInputMode(),
          maxLength,
        }}
        style={{
          backgroundColor: theme.palette.background.paper,
          zIndex: 0
        }}
        InputProps={{
          className: `${txtProps.className} ${readOnly ? classes.disabled : ''
            }`,
          value: putMask((value as string)),
          startAdornment: startAdornment,
          endAdornment: endAdornment !== null ? endAdornment : endAdornmentButton,
          autoComplete: autoComplete ?? 'off',
          type: type,
          onChange: onChangeWrapper,
          onBlur: onBlurWrapper,
          onKeyDown: onKeyDownWrapper,
        }}
        {...(register && txtProps.name ? register(txtProps.name) : null)}
        {...txtProps}
      />
    </>
  );
};

import { yupResolver } from '@hookform/resolvers/yup';
import { Button, Tooltip, Typography } from "@material-ui/core";
import classNames from "classnames";
import { useState } from "react";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import ConfirmaIcon from "../../../../../assets/img/icon-confirma.svg";
import { KeyValueModel } from "../../../../../model";
import { useToastSaurus } from '../../../../../services/app';
import { AutocompleteMedicamento } from "../../../../components/autocompletes/autocomplete-medicamentos";
import { DefaultCard, useDefaultCardStyles } from "../../../../components/cards/components";
import { TextFieldSaurus } from "../../../../components/controles/inputs";
import { SelectSaurus } from "../../../../components/controles/selects/select-saurus/select-saurus";
import { useGerenciarMedicamentos } from "../../../../components/dialog/dialog-dispensacao/use-dialog-gerenciar-medicamento-opcoes";
import { EditarIcon } from "../../../../components/icons";
import { useStyles } from "./compras-styles";
import { CompraAvulsaMedicamentoProp, CompraMedicamentoItem, EnumUnidadeComercial } from "./models/compras-cadastro.model";

export const CompraAvulsaMedicamentosCard = (({ medicamento, excluirMedicamento, setEditando }: CompraAvulsaMedicamentoProp) => {
  const styles = useStyles();
  const classesCard = useDefaultCardStyles();
  const [medicamentoEdicao, setMedicamentoEdicao] = useState(new CompraMedicamentoItem());
  const [isEdit, setIsEdit] = useState(false);
  const { showToastPersonalizado } = useToastSaurus();

  const schema = yup
    .object()
    .shape({
      quantidade: yup.number()
        .required("Itens é obrigatório!")
        .typeError("Itens é obrigatório!"),
      unidadeComercialId: yup.number()
        .required("Unidade é obrigatória!")
    });

  const {
    formState,
    register
  } = useForm<CompraMedicamentoItem>({
    criteriaMode: 'all',
    mode: 'onSubmit' && 'onTouched',
    resolver: yupResolver(schema)
  });

  const { errors } = formState;

  const handleMedicamentoAutocomplete = (medicamentoSelecionado: any) => {
    if (!medicamentoSelecionado.ms)
      return showToastPersonalizado('error', 'Este medicamento não pode ser selecionado, pois não possui o código da anvisa!', 3000, 'top-center');

    medicamento.medicamentoId = medicamentoSelecionado.id;
    medicamento.medicamentoNome = medicamentoSelecionado.descricao;
    medicamento.codigoAnvisa = medicamentoSelecionado.ms;
    medicamento.vendaControlada = medicamentoSelecionado.vendaControlada;
    medicamento.unidadeComercialId = medicamentoSelecionado.unidadeComercialId;

    setMedicamentoEdicao((medicamento) => ({
      ...medicamento,
      medicamentoId: medicamentoSelecionado.id,
      medicamentoNome: medicamentoSelecionado.descricao,
      codigoAnvisa: medicamentoSelecionado.ms,
      vendaControlada: medicamentoSelecionado.vendaControlada,
      unidadeComercialId: medicamentoSelecionado.unidadeComercialId
    }) as CompraMedicamentoItem);
  };

  const handleMedicamentoLabel = (): string =>
    medicamentoEdicao.codigoAnvisa ? `${medicamentoEdicao.medicamentoNome} - ${medicamentoEdicao.codigoAnvisa}` : '';

  const editMedicamento = async () => {

    var formIsValid = await schema.isValid(medicamentoEdicao);

    if (!formIsValid || !medicamentoEdicao.medicamentoNome)
      return showToastPersonalizado("error", "Preencha os campos obrigatórios!", 5000, 'top-center');

    medicamento.quantidade = medicamentoEdicao.quantidade;
    medicamento.unidadeComercialId = medicamentoEdicao.unidadeComercialId;
    setIsEdit(false);
    setEditando(false);
  }

  const { abrirGerenciarMedicamentos } = useGerenciarMedicamentos();

  const openModal = () => {
    abrirGerenciarMedicamentos(excluir, editar);
  };

  const excluir = () => {
    excluirMedicamento();
  }

  const editar = () => {
    setIsEdit(true);
    setMedicamentoEdicao(medicamento);
    setEditando(true);
  }

  const buscarValoresUnidadeComercial = (): KeyValueModel[] => {
    const valores: KeyValueModel[] = [];
    for (let key in EnumUnidadeComercial) {
      if (isNaN(Number(key))) {
        valores.push(new KeyValueModel(EnumUnidadeComercial[key], key));
      }
    }
    return valores;
  };

  const getDescricaoUnidadeComercial = (valor?: EnumUnidadeComercial) => {
    switch (valor) {
      case EnumUnidadeComercial.Caixas:
        return 'Caixas';
      case EnumUnidadeComercial.Frascos:
        return 'Frascos';
      default:
        return '';
    }
  };

  return (
    <>
      <DefaultCard>
        {isEdit ?
          <div className={classNames('flex-row', 'h-100', 'w-100', styles.infoContainerCompraAvulsa, styles.divCardMed)}>

            <div className={`flex-column w-100 ${styles.infoContainer}`}>
              <div className="flex-row w-100">
                <AutocompleteMedicamento
                  value={handleMedicamentoLabel()}
                  label="Buscar Medicamento"
                  className={`h-50 w-100 ${styles.inputMedStyle}`}
                  onChange={(e: any) => handleMedicamentoAutocomplete(e.value)}
                />
              </div>
              <div className={`flex-row w-100 ${styles.itensDiv}`}>

                <div className={`flex-column ${styles.widthInput}`}>
                  <Typography className={styles.labelFont}>
                    Código
                  </Typography>
                  <Typography className={styles.contentFont}>
                    {medicamentoEdicao.codigoAnvisa}
                  </Typography>
                </div>

                <div className={`flex-column ${styles.widthInput}`}>
                  <SelectSaurus
                    value={medicamentoEdicao.unidadeComercialId}
                    label="Undidade"
                    variant="outlined"
                    size="small"
                    conteudo={buscarValoresUnidadeComercial()}
                    className={`h-100 w-100 ${styles.inputMedStyle}`}
                    error={Boolean(errors.unidadeComercialId && !medicamentoEdicao.unidadeComercialId)}
                    helperText={
                      errors.unidadeComercialId && !medicamentoEdicao.unidadeComercialId
                        ? errors.unidadeComercialId?.message
                        : undefined
                    }
                    {...register('unidadeComercialId', {
                      onChange: (e: any) => setMedicamentoEdicao({ ...medicamentoEdicao, unidadeComercialId: e.target.value })
                    })}
                  />
                </div>

                <div className={`flex-column ${styles.widthInputItens}`}>
                  <TextFieldSaurus className={`h-100 w-100 ${styles.inputMedStyle}`}
                    required
                    value={medicamentoEdicao.quantidade}
                    labelCustom="Itens"
                    variant="outlined"
                    size="small"
                    tipo='NUMERO'
                    error={Boolean(errors.quantidade && !medicamentoEdicao.quantidade)}
                    helperText={
                      errors.quantidade && !medicamentoEdicao.quantidade
                        ? errors.quantidade?.message
                        : undefined
                    }
                    {...register('quantidade', {
                      onChange: (e: any) => setMedicamentoEdicao({ ...medicamentoEdicao, quantidade: e.target.value })
                    })}
                    min={undefined}
                    max={undefined}
                  />

                </div>
              </div>

              <div className={`flex-row w-100 ${styles.containerButtonAdd}`}>
                <div className={classNames(styles.addButtonStyle, styles.darkButton)}>
                  <Button onClick={() => editMedicamento()} variant="text" className="h-100 w-100">
                    <div className={`flex-row ${styles.buttonLabel} ${styles.whiteLabel}`}>
                      <img className={styles.addButtonIcon} src={ConfirmaIcon} alt="" />
                    </div>
                  </Button>
                </div>
              </div>

            </div>

          </div>
          :
          <div className={`flex-row h-100 w-100 ${styles.cardContainer}`}>

            <div className={`flex-column w-100 ${styles.infoContainer}`}>
              <div className="flex-column w-100">
                <Typography className={styles.labelFont}>
                  Medicamento
                </Typography>
                <Typography className={styles.contentFont}>
                  {medicamento.medicamentoNome}
                </Typography>
              </div>
              <div className="flex-row w-100">
                <div className="flex-column w-50">
                  <Typography className={styles.labelFont}>
                    Código
                  </Typography>
                  <Typography className={styles.contentFont}>
                    {medicamento.codigoAnvisa}
                  </Typography>
                </div>
                <div className="flex-column w-50">
                  <Typography className={styles.labelFont}>
                    Unidade
                  </Typography>
                  <Typography className={styles.contentFont}>
                    {getDescricaoUnidadeComercial(medicamento?.unidadeComercialId)}
                  </Typography>
                </div>
                <div className="flex-column w-50">
                  <Typography className={styles.labelFont}>
                    Itens
                  </Typography>
                  <Typography className={styles.contentFont}>
                    {medicamento.quantidade}
                  </Typography>
                </div>
              </div>
            </div>
            <Tooltip arrow title="Editar Medicamento">
              <div className={classesCard.cardRightButton}>
                <EditarIcon tipo="BUTTON" onClick={() => openModal()} />
              </div>
            </Tooltip>
          </div>
        }
      </DefaultCard>
    </>
  );
});
import { Typography } from "@material-ui/core";
import { useState } from "react";
import { MedicamentoPerda, PerdaAvulsaStepsProp } from "../models/perda-avulsa-models";
import { useStyles } from "../perda-avulsa-cadastro-style";
import { PerdaAvulsaMedicamentoAdicionadoCard } from "./perda-avulsa-medicamento-adicionado";
import { NovaPerdaAvulsaMedicamentosCard } from "./perda-avulsa-medicamentos-card";
import classNames from "classnames";

export const PerdaAvulsaNovoMedicamentoForm = (({ perda, setPerda }: PerdaAvulsaStepsProp) => {

    const styles = useStyles();
    const [editando, setEditando] = useState(false);

    const adicionarNovoMedicamento = (novoMedicamento: MedicamentoPerda) => {
        setPerda((perda) => ({
            ...perda,
            medicamentos: [...perda.medicamentos, novoMedicamento]
        }));
    }

    const excluirMedicamento = (index: number) => {
        perda.medicamentos.splice(index, 1);
        setPerda((perda) => ({
            ...perda,
            medicamentos: perda.medicamentos
        }));
    }

    return (
        <>
            <div className={`flex-column w-100 h-100`}>

                <div className={styles.containerMedInfoTitle}>
                    <Typography className={styles.titleStyle} variant="h6">Medicamentos</Typography>
                    <Typography className={styles.titleStyle} variant="subtitle2">Informe os dados dos medicamentos.</Typography>
                </div>

                <div className={classNames('flex-column', styles.innerFormContainerMedicamento, styles.scrollMeds)}>
                    {
                        perda.medicamentos?.map((medicamento, index) => (
                            <div key={index} className={classNames('flex-column', 'w-100', styles.cardMedicamentoItem, styles.cardMedicamentoAlign)}>
                                <PerdaAvulsaMedicamentoAdicionadoCard
                                    medicamento={medicamento}
                                    excluirMedicamento={() => { excluirMedicamento(index) }}
                                    setEditando={editando => setEditando(editando)}
                                />
                            </div>
                        ))
                    }

                    {!editando &&
                        <div className={classNames('flex-column', 'w-100', styles.cardMedicamentoItem, styles.cardMedicamentoAlign)}>
                            <NovaPerdaAvulsaMedicamentosCard
                                adicionarNovoMedicamento={novoMedicamento => adicionarNovoMedicamento(novoMedicamento)} />
                        </div>
                    }

                </div>
            </div>

        </>
    );
});
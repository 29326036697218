import { Control, FormState } from "react-hook-form";

export class CompraMedicamentoClass {
    constructor(
        public medicamentoNome: string,
        public medicamentoId: string,
        public codigoAnvisa: string,
        public validade: Date,
        public fabricacao: Date,
        public lote: string,
        public vendaControlada: boolean,
        public codigoAgregador: number,
        public motivoIsencao: string,
        public vpmc: 0,
    ) {

    }
};

export class ComprasMedicamentoAvulsa {
    constructor(
    ) {
        this.medicamentoId = "";
        this.codigo = 0;
        this.quantidade = 0;
    }

    medicamentoId: string;
    codigo: number;
    quantidade: number;
};

export enum EnumClasseTerapeutica {
    Antimicrobiano = 1,
    SujeitoControleEspecial = 2
}

export enum EnumUnidadeComercial {
    Caixas = 1,
    Frascos = 2
}

export class CompraMedicamentoItem {
    constructor(
    ) {
        this.medicamentoNome = "";
        this.medicamentoId = "";
        this.codigoAnvisa = "";
        this.validade = new Date()
        this.fabricacao = new Date();
        this.lote = "";
        this.vendaControlada = 0;
        this.codigoAgregador = 0;
        this.motivoIsencao = "";
        this.vpmc = 0;
        this.quantidade = 0;
        this.laboratorioId = "";
        this.laboratorio = "";
        this.codigoEan = "";
        this.unidadeComercialId = EnumUnidadeComercial.Caixas;
    }

    medicamentoNome: string;
    medicamentoId: string;
    codigoAnvisa: string;
    validade?: Date;
    fabricacao?: Date;
    lote?: string;
    vendaControlada: number;
    codigoAgregador?: number;
    motivoIsencao?: string;
    vpmc: 0;
    quantidade: number;
    laboratorioId: string;
    laboratorio: string;
    codigoEan: string;
    unidadeComercialId: EnumUnidadeComercial;
};

export class ComprasAvulsaCadastroModel {
    constructor(
    ) {
        this.dataAtual = "";
        this.horaAtual = "";
        this.dataHoraLancamento = "";
        this.dataNotaFiscal = "";
        this.numeroNotaFiscal = "";
        this.fornecedor = new CompraFornecedor();
        this.medicamentos = new Array<CompraMedicamentoItem>();
    }
    dataAtual: string;
    horaAtual: string;
    dataHoraLancamento: string;
    dataNotaFiscal: string;
    numeroNotaFiscal: string = '';
    fornecedor: CompraFornecedor;
    medicamentos: CompraMedicamentoItem[];
}

export class CompraFornecedor {
    constructor(
    ) {
        this.nome = "";
        this.cnpj = "";
        this.ie = "";
        this.telefone = "";
        this.cep = "";
        this.logradouro = "";
        this.numero = "";
        this.complemento = "";
        this.ufId = 0;
        this.municipioId = 0;
        this.licencaMunicipal = "";
        this.licencaMunicipalExpiracao = new Date();
        this.licencaAnvisa = "";
        this.licencaAnvisaExpiracao = new Date();
    }
    nome: string;
    cnpj: string;
    ie: string;
    telefone: string;
    cep: string;
    logradouro: string;
    numero: string;
    complemento: string;
    ufId: number;
    municipioId: number;
    licencaMunicipal: string;
    licencaMunicipalExpiracao: Date;
    licencaAnvisa: string;
    licencaAnvisaExpiracao: Date;
};

export enum ECompraPassos {
    NotaFiscal = 0,
    Medicamento = 1,
}

export class OperacaoCompraRequest {
    constructor(
        public id: string,
        public operacaoAutorizacaoId: string,
        public operacaoTipo: number,
        public dataHoraLancamento: Date,
        public deveAutorizar: boolean,
        public compra: ComprasCadastroModel
    ) { }
}

export class ComprasCadastroModel {
    constructor(
        public dataLancamento: string,
        public horaLancamento: string,
        public dataHoraLancamento: Date,
        public diaNotaFiscal: string,
        public dataNotaFiscal: Date,
        public numeroNotaFiscal: string = '',
        public fornecedor: CompraFornecedorResponse = {} as CompraFornecedorResponse,
        public medicamentos: CompraMedicamentoResponse[] = []
    ) { }
}

export interface CompraResponse {
    id: string;
    dataHoraLancamento: Date;
    dataNotaFiscal: Date;
    numeroNotaFiscal: string;
    fornecedor: CompraFornecedorResponse;
    medicamentos: Array<CompraMedicamentoResponse>;
};

export interface CompraFornecedorResponse {
    nome: string;
    cnpj: string;
    ie: string;
    telefone: string;
    cep: string;
    logradouro: string;
    numero: string;
    complemento: string;
    ufId: number;
    municipioId: number;
    licencaMunicipal: string;
    licencaMunicipalExpiracao: Date;
    licencaAnvisa: string;
    licencaAnvisaExpiracao: Date;
};

export interface CompraMedicamentoResponse {
    medicamentoNome: string;
    medicamentoId: string;
    codigoAnvisa: string;
    validade?: Date;
    fabricacao?: Date;
    lote?: string;
    vendaControlada: boolean;
    codigoAgregador?: number;
    motivoIsencao?: string;
    unidadeComercialId: EnumUnidadeComercial;
    vpmc: 0;
};

export interface ComprasMedicamentoLote {
    lote: string;
    quantidade: number;
    quantidadeReservada: number;
    dataValidade: Date;
    dataFabricacao?: Date;
    codigoAgregador?: number;
};

export class NovoLote {
    constructor(
        public lote: string = "",
        public dataValidade: Date | undefined = undefined,
        public dataFabricacao: Date | undefined = undefined,
        public quantidade: number = 0
    ) { }
}

export interface CompraStepsProp {
    visualizacao: boolean;
    compra: ComprasCadastroModel;
    control: Control<ComprasCadastroModel, any>;
    formState: FormState<ComprasCadastroModel>;
    setCompra: React.Dispatch<React.SetStateAction<ComprasCadastroModel>>;
    editarMedicamentoLotes?: (medicamentos: CompraMedicamentoResponse[]) => void;
    register: any;
    errorValidation?: boolean;
}
export interface CompraAvulsaStepsProp {
    compra: ComprasAvulsaCadastroModel;
    control: Control<ComprasAvulsaCadastroModel, any>;
    formState: FormState<ComprasAvulsaCadastroModel>;
    setCompra: React.Dispatch<React.SetStateAction<ComprasAvulsaCadastroModel>>;
}
export interface CompraAvulsaStepsNotaFiscalProp {
    compra: ComprasAvulsaCadastroModel;
    control: Control<ComprasAvulsaCadastroModel, any>;
    register: any;
    formState: FormState<ComprasAvulsaCadastroModel>;
    setCompra: React.Dispatch<React.SetStateAction<ComprasAvulsaCadastroModel>>;
    errorValidation?: boolean;
}
export interface CompraMedicamentoLotesProp {
    visualizacao: boolean;
    medicamentos: CompraMedicamentoResponse[];
    editar: (medicamentos: CompraMedicamentoResponse[]) => void;
}
export interface CompraAvulsaMedicamentoProp {
    medicamento: CompraMedicamentoItem;
    formState: FormState<ComprasAvulsaCadastroModel>;
    excluirMedicamento: () => void;
    setEditando: (editando: boolean) => void;
}
export interface CompraAvulsaMedicamentoLotesProp {
    adicionarNovoMedicamento: (comprasMedicamentoAvulsa: CompraMedicamentoItem) => void;
}
export interface ComprasMedicamentoLotesProps {
    visualizacao: boolean;
    medicamentos: CompraMedicamentoResponse[];
    salvarLotesCompras: (lotesSelecionados: ComprasMedicamentoLote[], codigoAnvisa: string) => void;
}
export interface ComprasAvulsaMedicamentoLotesProps {
    medicamento: CompraMedicamentoItem;
    salvarLotesCompras: (lotesSelecionados: ComprasMedicamentoLote[], codigoAnvisa: string) => void;
    voltar: () => void;
}
export interface ComprasMedicamentoLoteCardProp {
    visualizacao: boolean;
    lote: ComprasMedicamentoLote;
    removerLote: (lote: string) => void;
}
export interface ComprasMedicamentoNovoLoteCardProp {
    quantidadeRestante: number;
    adicionarLote: (novoLote: NovoLote) => void;
}

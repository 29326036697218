import { yupResolver } from '@hookform/resolvers/yup';
import { Button, Typography } from "@material-ui/core";
import classNames from "classnames";
import { useState } from "react";
import { Controller, useForm } from "react-hook-form";
import * as yup from "yup";
import ConfirmaIcon from "../../../../../assets/img/icon-confirma.svg";
import { useToastSaurus } from '../../../../../services/app';
import { AutocompleteMedicamento } from "../../../../components/autocompletes/autocomplete-medicamentos";
import { DefaultCard } from "../../../../components/cards/components";
import { TextFieldSaurus } from "../../../../components/controles/inputs";
import { MedicamentoPerda, PerdaAvulsaMedicamentoLotesProp } from "../models/perda-avulsa-models";
import { useStyles } from "../perda-avulsa-cadastro-style";
import { useGetEstoqueByCodigoAnvisa } from '../../../../../data/api/gestao/estoque/get-estoque';

export const NovaPerdaAvulsaMedicamentosCard = (({ adicionarNovoMedicamento }: PerdaAvulsaMedicamentoLotesProp) => {
    const styles = useStyles();
    const { showToastPersonalizado } = useToastSaurus();
    const [, setAtt] = useState(false)
    const { getEstoqueDisponivelByCodigoAnvisa } = useGetEstoqueByCodigoAnvisa();
    const { showToast } = useToastSaurus()

    const schema = yup
        .object()
        .shape({
            quantidade: yup.number().required("Item é obrigatório!").typeError('Item é obrigatório!'),
            medicamentoNome: yup.string().required('Selecione o medicamento')
        });

    const {
        formState: { errors },
        control,
        setValue,
        getValues,
        reset,
        handleSubmit
    } = useForm<MedicamentoPerda>({
        criteriaMode: 'all',
        mode: 'onChange',
        resolver: yupResolver(schema)
    });

    const getEstoqueMedicamento = async (codigoAnvisa: string) => {
        try {
            const res = await getEstoqueDisponivelByCodigoAnvisa(codigoAnvisa)
            if (res.erro) {
                throw res.erro
            }

            const resultado = res.resultado?.data


            const lotes = resultado?.lotes;
            if (!resultado || (lotes ?? 0)?.length === 0) {
                showToast('error', 'O Medicamento selecionado não existe no Estoque!', 3000, 'top-center')
                return false
            }

            return true
        } catch (err: any) {
            showToast('error', 'Ocorreu um erro ao validar o estoque. Detalhe:' + err?.message || '', 3000, 'top-center')
            return false
        }
    }

    const submit = async (values: MedicamentoPerda) => {
        adicionarNovoMedicamento(values);
        reset(new MedicamentoPerda());
        setAtt(prev => !prev)
    }

    const handleMedicamentoAutocomplete = async (medicamento: any) => {
        if (!medicamento) return
        const medicamentoAnterior = getValues('codigoAnvisa')
        console.log(medicamentoAnterior, medicamento.ms)
        if (!medicamento.ms)
            return showToastPersonalizado('error', 'Este medicamento não pode ser selecionado, pois não possui o código da anvisa!', 3000, 'top-center');

        if (medicamento.ms !== medicamentoAnterior) {
            const hasEstoque = await getEstoqueMedicamento(medicamento.ms)
            if (!hasEstoque) {
                return
            }
        }
        setValue('medicamentoId', medicamento.id)
        setValue('medicamentoNome', medicamento.descricao)
        setValue('codigoAnvisa', medicamento.ms)
        setValue('vendaControlada', medicamento.vendaControlada)
        setAtt(prev => !prev)
    };

    const handleMedicamentoLabel = (): string =>
        getValues('codigoAnvisa') ? `${getValues('medicamentoNome')} - ${getValues('codigoAnvisa')}` : '';

    return (
        <>
            <DefaultCard>
                <form onSubmit={handleSubmit(submit)}>
                    <div className={classNames('flex-row', 'h-100', 'w-100', styles.infoContainerPerdaAvulsa, styles.divCardMed)}>
                        <div className={`flex-column w-100 ${styles.infoContainer}`}>
                            <div className="flex-column w-100">
                                <AutocompleteMedicamento
                                    value={handleMedicamentoLabel()}
                                    label="Buscar Medicamento *"
                                    className={`h-50 w-100 ${styles.inputStyle}`}
                                    onChange={(e: any) => handleMedicamentoAutocomplete(e.value)}
                                    error={Boolean(errors.medicamentoNome)}
                                    helperText={
                                        errors.medicamentoNome
                                            ? errors.medicamentoNome?.message
                                            : undefined
                                    }
                                />
                            </div>
                            <div className={`flex-row w-100 ${styles.itensDiv}`}>
                                <div className="flex-column w-50">
                                    <Typography className={styles.labelFont}>
                                        Código
                                    </Typography>
                                    <Typography className={styles.contentFont}>
                                        {getValues('codigoAnvisa')}
                                    </Typography>
                                </div>

                                <div className="flex-column w-50">
                                    <Controller
                                        control={control}
                                        name='quantidade'
                                        render={({ field }) => (
                                            <TextFieldSaurus className={`h-100 w-100 ${styles.inputStyle}`}
                                                labelCustom="Itens *"
                                                variant="outlined"
                                                size="small"
                                                tipo='NUMERO'
                                                error={Boolean(errors.quantidade)}
                                                helperText={
                                                    errors.quantidade && errors.quantidade?.message
                                                        ? errors.quantidade?.message
                                                        : undefined
                                                }
                                                {...field}
                                                maxLength={7}
                                            />
                                        )}
                                    />
                                </div>
                            </div>
                        </div>

                        <div className={classNames(styles.addButtonStyle, styles.darkButton)}>
                            <Button type="submit" variant="text" className="h-100 w-100">
                                <div className={`flex-row ${styles.buttonLabel} ${styles.whiteLabel}`}>
                                    <img className={styles.addButtonIcon} src={ConfirmaIcon} alt="" />
                                </div>
                            </Button>
                        </div>
                    </div>
                </form>
            </DefaultCard>
        </>
    );
});
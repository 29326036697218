
import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
    color: {
        backgroundColor: theme.palette.grey[100],
    },
    root: {
        width: "100%",
        height: "100%",
        margin: 0,
        position: "relative",
        padding: theme.spacing(1),
        paddingTop: theme.spacing(2),
        background: theme.palette.common.white,
        [theme.breakpoints.up("md")]: {
            borderTopWidth: 1,
            borderTopStyle: "solid",
            borderTopColor: theme.palette.grey[300],
            background: theme.palette.grey[50],
        }
    },
    card: {
        width: '100%',
        height: '100%',
        padding: `${theme.spacing(2)}px!important`,
        boxShadow: '0px 41.9453px 109.058px rgba(0, 0, 0, 0.05)',
        cursor: 'auto',
        [theme.breakpoints.down('xs')]: {
            width: "100%",
            boxShadow: '0px 41.9453px 109.058px rgba(0, 0, 0, 0.05)',
            margin: '0 auto'
        }
    },
    cardMovimentacaoItem: {
        justifyContent: 'start',
        gap: '1%'
    },
    labelFont: {
        fontWeight: 700,
        fontSize: "0.6rem",
        color: theme.palette.common.black,
        paddingRight: '0.25rem',
        [theme.breakpoints.up("md")]: {
            fontSize: "0.75rem",
        }
    },
    contentFont: {
        fontSize: "0.6rem",
        color: theme.palette.secondary.main,
        [theme.breakpoints.up("md")]: {
            fontSize: "0.75rem",
        }
    },
    titleStyle: {
        paddingLeft: '1rem',
        fontSize: '1.3rem',
        fontWeight: 500,
    },
    breakLine: {
        height: '2px',
        marginBottom: '1vh',
        backgroundColor: theme.palette.divider,
    },
    semRegistroStyle: {
        textAlign: 'center',
        margin: '1rem 0',
        fontSize: '1rem'
    },
    contentContainer: {
        flexGrow: 1,
        alignItems: "center",
        padding: '0 3%',
        marginTop: '0.5rem',
        [theme.breakpoints.up("md")]: {
            padding: '0 1%'
        }
    },
    listPadding: {
        width: "100%",
        justifyContent: "space-between",
        [theme.breakpoints.down("lg")]: {
            width: '100%'
        }

    },
    cardContainer: {
        justifyContent: 'center',
    },
    cardPadding: {
        padding: '0.25rem 0.5rem',
    },
    statusColor: {
        backgroundColor: theme.palette.info.main
    },
    listContainer: {
        justifyContent: 'start',
        gap: '2%'
    },
    statusBarContainer: {
        display: 'flex',
        alignItems: 'center'
    },
    statusBarStyle: {
        width: '0.3rem',
        height: '90%',
        margin: '0 0.5rem',
        borderRadius: '12px',
    },
    infoContainer: {
        margin: '0.3rem 0.5rem',
        justifyContent: 'space-between',
        width: '100%',
    },
    optionsButtonContainer: {
        display: 'flex',
        width: 'fit-content',
        alignItems: 'center',
        paddingRight: '0.5rem'
    },
    buttonContainer: {
        height: '4rem',
        gap: '1.2rem',
        marginBottom: '0.8rem',
        [theme.breakpoints.up('sm')]: {
            width: '70%',
            alignSelf: 'center',
        }
    },
    darkLabel: {
        color: theme.palette.primary.main,
        fill: theme.palette.primary.main,
    },
    optionsButtonStyle: {
        padding: '0.15rem',
        color: theme.palette.common.black
    },
    inputContainer: {
        display: 'flex',
        height: '27%',
        [theme.breakpoints.up('sm')]: {
            gap: '2%'
        },
        gap: '4%',
        marginTop: 10
    },
    inputDateContainer: {
        display: 'flex',
        flexDirection: 'column',
        width: '66%',
        [theme.breakpoints.down('sm')]: {
            width: '100%',
        }
    },
    inputDateAlign: {
        display: 'flex',
        width: '100%',
        gap: '2%'
    },
    inputStyle: {
        borderRadius: '10px',
        height: '100%',
    },
    inputAlign: {
        width: '50%',
        height: '3.5rem'
    },
    inputAlignOperacao: {
        width: '32%',
        height: '3.5rem',
        [theme.breakpoints.down('sm')]: {
            width: '100%',
            marginBottom: '5%'
        },
    },
    container: {
        display: "flex",
        flexDirection: "column",
        width: '100%',
        height: '100%',
    },
    containerInput: {
        display: "flex",
        flexDirection: "row",
        gap: '2%',
        width: '100%',
        flexWrap: 'wrap',
        [theme.breakpoints.down('sm')]: {
            gap: '4%'
        },
    },
    margin: {
        marginBottom: '1.5rem'
    },
    containerButton: {
        display: "flex",
        flexDirection: "row",
        width: '100%',
        marginTop: '1.5rem',
        [theme.breakpoints.up('sm')]: {
            maxHeight: "3.5rem",
        },
    },
    buttonHeight: {
        height: 'auto'
    },
    containerButtonMaxHeight: {
        display: "flex",
        flexDirection: "row",
        width: '100%',
        marginTop: 10,
        marginBotton: 10,
        alignItems: 'center',
        maxHeight: "5vh"
    },
    containerMarginTop: {
        marginTop: 10
    },
    buttonStyle: {
        flexGrow: 1,
        borderRadius: '10px',
        [theme.breakpoints.down('sm')]: {
            height: '4rem'
        },
    },
    buttonStyleXml: {
        flexGrow: 1,
        borderRadius: '10px',
    },
    whiteButton: {
        color: theme.palette.primary.main,
        backgroundColor: theme.palette.common.white,
        border: `2px solid ${theme.palette.primary.main}`
    },
    darkButton: {
        color: theme.palette.common.white,
        backgroundColor: theme.palette.primary.main,
    },
    whiteLabel: {
        color: theme.palette.common.white,
        fill: theme.palette.common.white,
    },
    buttonLabel: {
        fontSize: '1rem',
        [theme.breakpoints.up('md')]: {
            fontSize: '1.7rem'
        },
    },
    downloadButton: {
        color: theme.palette.common.white,
        backgroundColor: theme.palette.success.main,
    },
    containerButtonBuscar: {
        paddingBottom: 10
    },
    fontBold: {
        fontWeight: 'bold'
    },
    modal: {
        backgroundColor: 'rgba(0, 0, 0, 0.5) !important',
    },
    rules: {
        display: 'flex',
        flexDirection: 'column',
        marginBottom: '2%'
    }
}));
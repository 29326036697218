import { Tooltip, Typography } from "@material-ui/core";
import moment from "moment";
import { ReactNode, useCallback, useState } from "react";
import { useHistory } from "react-router";
import { useToastSaurus } from "../../../../../services/app";
import { DefaultCard, useDefaultCardStyles } from "../../../../components/cards/components";
import { useAutorizacaoOpcoes } from "../../../../components/dialog/dialog-autorizacao-opcoes/use-dialog-autorizacao-opcoes";
import { EditarIcon } from "../../../../components/icons";
import { CompraModal } from "../../compras/componentes/modals/modal-compra/compra-modal";
import { DispensacaoModal } from "../../dispensacoes-avulsas/modals/modal-dispensacao/dispensacao-modal";
import { PerdaModal } from "../../perdas-avulsas/modals/modal-perda/perda-modal";
import { TransferenciaModal } from "../../transferencias/modals/modal-transferencia/transferencia-modal";
import {
  ETipoOperacaoTransferencia,
  ETipoOperacaoTransferenciaDescricao,
  OperacaoAutorizacaoEnum,
  OperacaoAutorizacaoEnumDescricao
} from "../model/autorizacao.model";
import { AutorizacaoCardProp } from "./autorizacao-card-props";
import { EMotivoPerdaMap } from "../../perdas/models/perdas-models";

export const AutorizacaoCard = ({ operacao, inventarioAndamento, aprovar, reprovar, atualizarLista }: AutorizacaoCardProp) => {
  const classes = useDefaultCardStyles();
  const { abrirAutorizacaoOpcoes } = useAutorizacaoOpcoes();
  const history = useHistory();
  const { showToast } = useToastSaurus();
  const [modalOpen, setModalOpen] = useState(false);
  const [modal, setModal] = useState(null as ReactNode);

  const isOperacaoValida = useCallback(() => {
    if (operacao.usuarioAptoAutorizar === false) {
      showToast('none', 'Usuário sem permissão. Somente usuários com o perfil de responsável técnico podem aprovar uma operação pendente.', 5000, 'top-center');
      return false;
    }

    if (inventarioAndamento === true && operacao.operacaoTipo !== OperacaoAutorizacaoEnum.Inventario) {
      showToast('none', 'Operação bloqueada. Aguarde a finalização do Inventário!', 5000, 'top-center');
      return false;
    }

    return true;
  }, [inventarioAndamento, operacao, showToast])

  const fecharModal = () => {
    setModalOpen(false);
    atualizarLista();
  }

  const handleOperacaoModal = useCallback(() => {
    switch (operacao.operacaoTipo) {
      case OperacaoAutorizacaoEnum.Compra:
        return (
          <CompraModal
            openned={true}
            setModalOpen={fecharModal}
            operacaoId={operacao.id}
          />
        );
      case OperacaoAutorizacaoEnum.Venda:
        return (
          <DispensacaoModal
            openned={true}
            setModalOpen={fecharModal}
            operacaoId={operacao.id}
          />
        );
      case OperacaoAutorizacaoEnum.Transferencia:
        return (
          <TransferenciaModal
            openned={true}
            setModalOpen={fecharModal}
            operacaoId={operacao.id}
          />
        );
      case OperacaoAutorizacaoEnum.Perda:
        return (
          <PerdaModal
            openned={true}
            setModalOpen={fecharModal}
            operacaoId={operacao.id}
          />
        );
      default: return;
    }
  }, [operacao])

  const abrirModal = useCallback(() => {
    if (!isOperacaoValida()) return;
    setModalOpen(true);
    return setModal(handleOperacaoModal());
  }, [handleOperacaoModal, isOperacaoValida]);

  const navegarEdicaoOperacao = useCallback(() => {
    if (operacao.operacaoTipo === OperacaoAutorizacaoEnum.Inventario)
      history.push({ pathname: '/inventario-listagem' });
    else
      abrirModal();
  }, [abrirModal, history, operacao.operacaoTipo]);

  const openModal = useCallback(() => {
    if (!isOperacaoValida())
      return;

    if (operacao.id && operacao.operacaoAptaAutorizar !== undefined && (operacao.operacaoTipo || operacao.operacaoTipo === OperacaoAutorizacaoEnum.Compra))
      abrirAutorizacaoOpcoes(operacao.id, operacao.operacaoAptaAutorizar, aprovar, reprovar, navegarEdicaoOperacao);
  }, [abrirAutorizacaoOpcoes, aprovar, isOperacaoValida, navegarEdicaoOperacao, operacao.id, operacao.operacaoTipo, operacao.operacaoAptaAutorizar, reprovar]);

  const hasApenasUmMed = (operacao.perda?.medicamentos ?? [])?.length <= 1

  const handleOperacaoInfo = () => {
    switch (operacao.operacaoTipo) {
      case OperacaoAutorizacaoEnum.Compra:
        return (
          <div className={`flex-row ${classes.infoContainer}`} onClick={() => navegarEdicaoOperacao()}>
            <div className={`flex-column ${classes.mainInputMinWidth}`}>
              <Typography className={classes.labelFont}>
                Fornecedor:
              </Typography>
              <Typography className={classes.contentFont}>
                {operacao.compra?.fornecedor?.nome ?? "Fornecedor não informado"}
              </Typography>
            </div>
            <div className={`flex-column ${classes.inputMinWidth}`}>
              <Typography className={classes.labelFont}>
                Nº NF:
              </Typography>
              <Typography className={classes.contentFont}>
                {operacao.compra?.numeroNotaFiscal}
              </Typography>
            </div>
            <div className={`flex-column ${classes.inputMinWidth}`}>
              <Typography className={classes.labelFont}>
                Data:
              </Typography>
              <Typography className={classes.contentFont}>
                {formatData(operacao.compra?.dataHoraLancamento)}
              </Typography>
            </div>
            <div className={`flex-column ${classes.inputMinWidth}`}>
              <Typography className={classes.labelFont}>
                Qtd:
              </Typography>
              <Typography className={classes.contentFont}>
                {operacao.compra?.medicamentos?.length ?? 0}
              </Typography>
            </div>
          </div>
        );
      case OperacaoAutorizacaoEnum.Venda:
        return (
          <div className={`flex-row w-100 ${classes.infoContainer}`} onClick={() => navegarEdicaoOperacao()}>
            <div className={`flex-column ${classes.mainInputMinWidth}`}>
              <Typography className={classes.labelFont}>
                Comprador:
              </Typography>
              <Typography className={classes.contentFont}>
                {showComprador()}
              </Typography>
            </div>
            {operacao.dispensacao?.numeroNotaFiscal &&
              <div className={`flex-column ${classes.inputMinWidth}`}>
                <Typography className={classes.labelFont}>
                  Nº NF:
                </Typography>
                <Typography className={classes.contentFont}>
                  {operacao.dispensacao?.numeroNotaFiscal}
                </Typography>
              </div>
            }
            <div className={`flex-column ${classes.inputMinWidth}`}>
              <Typography className={classes.labelFont}>
                Receita:
              </Typography>
              <Typography className={classes.contentFont}>
                {
                  operacao.dispensacao?.medicamentos?.[0]?.receita?.numeroReceita ?? "-"
                }
              </Typography>
            </div>
            <div className={`flex-column ${classes.inputMinWidth}`}>
              <Typography className={classes.labelFont}>
                Data:
              </Typography>
              <Typography className={classes.contentFont}>
                {formatData(operacao.dispensacao?.dataHoraLancamento)}
              </Typography>
            </div>
            <div className={`flex-column ${classes.inputMinWidth}`}>
              <Typography className={classes.labelFont}>
                Qtd:
              </Typography>
              <Typography className={classes.contentFont}>
                {operacao.dispensacao?.medicamentos?.length ?? 0}
              </Typography>
            </div>
          </div>
        );
      case OperacaoAutorizacaoEnum.Transferencia:
        return (
          <div className={`flex-row w-100 ${classes.infoContainer}`} onClick={() => navegarEdicaoOperacao()}>
            <div className={`flex-column ${classes.mainInputMinWidth}`}>
              <Typography className={classes.labelFont}>
                Loja:
              </Typography>
              <Typography className={classes.contentFont}>
                {
                  operacao.transferencia?.tipoOperacaoTransferencia === ETipoOperacaoTransferencia.Entrada
                    ? `(${operacao.transferencia?.origem?.cnpj}) ${operacao.transferencia?.origem?.nome}`
                    : `(${operacao.transferencia?.destino?.cnpj}) ${operacao.transferencia?.destino?.nome}`
                }
              </Typography>
            </div>
            <div className={`flex-column ${classes.inputMinWidth}`}>
              <Typography className={classes.labelFont}>
                Nº NF:
              </Typography>
              <Typography className={classes.contentFont}>
                {operacao.transferencia?.numeroNotaFiscal}
              </Typography>
            </div>
            <div className={`flex-column ${classes.inputMinWidth}`}>
              <Typography className={classes.labelFont}>
                Data:
              </Typography>
              <Typography className={classes.contentFont}>
                {formatData(operacao.transferencia?.dataHoraLancamento)}
              </Typography>
            </div>
            <div className={`flex-column ${classes.inputMinWidth}`}>
              <Typography className={classes.labelFont}>
                Movimento:
              </Typography>
              <Typography className={classes.contentFont}>
                {
                  operacao.transferencia?.tipoOperacaoTransferencia
                    ? ETipoOperacaoTransferenciaDescricao.get(operacao.transferencia?.tipoOperacaoTransferencia)
                    : "-"
                }
              </Typography>
            </div>
          </div>
        );
      case OperacaoAutorizacaoEnum.Inventario:
        return (
          <div className={`flex-row w-100 ${classes.infoContainer}`} onClick={() => navegarEdicaoOperacao()}>
            <div className={`flex-column ${classes.inputMinWidth}`}>
              <Typography className={classes.labelFont}>
                Inventário
              </Typography>
            </div>
            <div className={`flex-column ${classes.inputMinWidth}`}>
              <Typography className={classes.labelFont}>
                Data:
              </Typography>
              <Typography className={classes.contentFont}>
                {formatData(operacao.inventario?.data)}
              </Typography>
            </div>
            <div className={`flex-column ${classes.inputMinWidth}`}>
              <Typography className={classes.labelFont}>
                Total Tipos de Medicamentos:
              </Typography>
              <Typography className={classes.contentFont}>
                {operacao.inventario?.medicamentos?.length}
              </Typography>
            </div>
            <div className={`flex-column ${classes.inputMinWidth}`}>
              <Typography className={classes.labelFont}>
                Total de Medicamentos:
              </Typography>
              <Typography className={classes.contentFont}>
                {operacao.inventario?.quantidadeTotalMedicamentos}
              </Typography>
            </div>
          </div>
        );
      case OperacaoAutorizacaoEnum.Perda:
        return (
          <div className={`flex-row w-100 ${classes.infoContainer}`} onClick={() => navegarEdicaoOperacao()}>
            {hasApenasUmMed && (
              <>
                <div className={`flex-column ${classes.mainInputMinWidth}`}>
                  <Typography className={classes.labelFont}>
                    Medicamento:
                  </Typography>
                  <Typography className={classes.contentFont}>
                    {
                      operacao.perda?.medicamentos?.length
                        ? operacao.perda?.medicamentos[0].medicamentoNome
                        : "Medicamento não informado"
                    }
                  </Typography>
                </div>
                <div className={`flex-column ${classes.inputMinWidth}`}>
                  <Typography className={classes.labelFont}>
                    MS:
                  </Typography>
                  <Typography className={classes.contentFont}>
                    {
                      operacao.perda?.medicamentos?.length
                        ? operacao.perda?.medicamentos[0].codigoAnvisa
                        : "-"
                    }
                  </Typography>
                </div>
              </>
            )}
            <div className={`flex-column ${hasApenasUmMed ? classes.inputMinWidth : classes.mainInputMinWidth}`}>
              <Typography className={classes.labelFont}>
                Motivo:
              </Typography>
              <Typography className={classes.contentFont}>
                {
                  EMotivoPerdaMap.get(operacao.perda?.motivoPerda ?? 1)
                }
              </Typography>
            </div>
            <div className={`flex-column ${classes.inputMinWidth}`}>
              <Typography className={classes.labelFont}>
                Data:
              </Typography>
              <Typography className={classes.contentFont}>
                {formatData(operacao.perda?.dataHoraLancamento)}
              </Typography>
            </div>
            <div className={`flex-column ${classes.inputMinWidth}`}>
              <Typography className={classes.labelFont}>
                Qtd:
              </Typography>
              <Typography className={classes.contentFont}>
                {operacao.perda?.medicamentos?.length ?? 0}
              </Typography>
            </div>
          </div>
        );
    }
  };

  const handleOperacaoSigla = (tipo: OperacaoAutorizacaoEnum | undefined): string => {
    const descricao = String(OperacaoAutorizacaoEnumDescricao.get(tipo!));
    return descricao[0]?.toUpperCase();
  };

  const showComprador = (): string => {
    if (!operacao.dispensacao?.medicamentos![0].receita?.paciente?.nome &&
      !operacao.dispensacao?.medicamentos![0].receita?.comprador?.nome)
      return "Comprador não informado";

    return operacao.dispensacao?.medicamentos![0].receita?.compradorPacienteMesmaPessoa
      ? operacao.dispensacao?.medicamentos![0].receita?.paciente?.nome ?? "Comprador não informado"
      : operacao.dispensacao?.medicamentos![0].receita?.comprador?.nome ?? "Comprador não informado";
  }

  const verificarTagStatus = (): boolean =>
    operacao.usuarioAptoAutorizar === true && operacao.operacaoAptaAutorizar === true;

  const formatData = (data?: Date): string => moment(data).format("DD/MM/YYYY");

  return (
    <DefaultCard>
      <div className={`flex-row w-100 h-100`}>

        <div className={`${classes.tagStatus}`} style={{
          backgroundColor: verificarTagStatus() ? 'var(--sistema-sem-acao)' : 'var(--primaria-vermelho)'
        }} />

        <div className={classes.infoTagContainer}>
          <Typography className={classes.infoTagStyle}>
            {handleOperacaoSigla(operacao.operacaoTipo)}
          </Typography>
        </div>

        {handleOperacaoInfo()}

        {modalOpen && (modal)}

        <Tooltip arrow title="Editar Operação">
          <div className={classes.cardRightButton}>
            <EditarIcon tipo="BUTTON" onClick={() => openModal()} />
          </div>
        </Tooltip>
      </div>
    </DefaultCard >
  );
};

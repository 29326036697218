import { Button } from "@material-ui/core";
import axios from "axios";
import classNames from "classnames";
import moment from "moment";
import { useCallback, useEffect, useState } from "react";
import toast from "react-hot-toast";
import { useGetMovimentacoes } from "../../../../../../data/api/gestao/movimentacao/movimentacao";
import { KeyValueModel } from "../../../../../../model";
import { useToastSaurus } from "../../../../../../services/app";
import { CircularLoading } from "../../../../../components";
import { DefaultCard } from "../../../../../components/cards/components";
import { TextFieldSaurus } from "../../../../../components/controles/inputs";
import { SelectSaurus } from "../../../../../components/controles/selects/select-saurus/select-saurus";
import { ETipoOperacaoMovimentacao, ETipoOperacaoMovimentacaoMap, MovimentacaoFiltroProp, MovimentacaoModel } from "../../models/envio-movimentacao.model";
import { useStyles } from "../envio-movimentacao-listagem-styles";
import { isEmpty } from "lodash";

export const EnvioMovimentacaoListFiltro = (({
  control,
  buscarMovimentacoes,
  limparCardMovimentacoes,
  validarConflitoPeriodo,
  resetarFiltro,
  setResetarFiltro }: MovimentacaoFiltroProp) => {

  const { getPeriodoInicial, carregando: carregandoPeriodoInicial } = useGetMovimentacoes();
  const { showToast } = useToastSaurus();

  const [periodoInicial, setPeriodoInicial] = useState(new Date());
  const [periodoFinal, setPeriodoFinal] = useState(new Date());

  const [temMovimentacao, setTemMovimentacao] = useState(false);
  const [movimentacaoFiltro, setMovimentacaoFiltro] = useState({} as MovimentacaoModel);
  const [tipoOperacaoSelecionado, setTipoOperacaoSelecionado] = useState(1);
  const [errors, setErrors] = useState<string[]>([])
  const styles = useStyles();

  const listaTipoOperacao = Object.values(ETipoOperacaoMovimentacao).filter(e => !isNaN(Number(e)))
    .map((tipo: any) => new KeyValueModel(tipo, ETipoOperacaoMovimentacaoMap.get(tipo)));

  const buscarMovimentacao = () => {
    setErrors([])

    if (!movimentacaoFiltro.dataFinal || !movimentacaoFiltro.dataInicial || movimentacaoFiltro.tipoOperacao == null) {
      if (!movimentacaoFiltro.dataFinal) setErrors(prev => [...prev, 'dataFinal'])
      if (!movimentacaoFiltro.dataInicial) setErrors(prev => [...prev, 'dataInicial'])
      if (!movimentacaoFiltro.tipoOperacao) setErrors(prev => [...prev, 'tipoOperacao'])
      showToast('error', 'Preencha todos os campos!')
      return;
    }

    buscarMovimentacoes(movimentacaoFiltro);
    validarPeriodo();
  }

  function verificarPeriodoPermitido(): boolean {
    const dataAtual = new Date();

    if (moment(movimentacaoFiltro.dataFinal).isSame(dataAtual, 'day')) {
      showToast('error', 'A Data Final não pode ser a data de Hoje!');
      return false;
    }

    if (moment(movimentacaoFiltro.dataFinal).isAfter(moment(dataAtual), 'day')) {
      showToast('error', 'A Data Final não pode ser maior que a data de Hoje!')
      return false;
    }

    if (moment(movimentacaoFiltro.dataFinal).isBefore(moment(movimentacaoFiltro.dataInicial), 'day')) {
      showToast('error', 'A Data Final não pode ser menor que a Data Inicial!')
      return false;
    }

    const dataInicio = moment(movimentacaoFiltro.dataInicial).startOf('day');
    const dataFim = moment(movimentacaoFiltro.dataFinal).startOf('day');

    let diferencaEmDias = moment(dataFim).diff(dataInicio, 'days') + 1

    if (diferencaEmDias > 7) {
      showToast('error', 'O período máximo é de 7 dias!')
      return false;
    }

    return true;

  }

  const dataMaxima = useCallback((dataInicial: any = undefined) => {
    var dataInicialMovimentacao = "";

    if (dataInicial !== undefined)
      dataInicialMovimentacao = moment(dataInicial).format("YYYY-MM-DDT00:00:00");
    else
      dataInicialMovimentacao = moment(movimentacaoFiltro.dataInicial).format("YYYY-MM-DDT00:00:00");

    var dataInicialMovimentacaoDate = new Date(dataInicialMovimentacao);

    var dataLimiteFormatada = moment(dataInicialMovimentacaoDate.setDate(dataInicialMovimentacaoDate.getDate() + 6)).format("YYYY-MM-DD");

    var dataLimite = new Date(new Date(dataLimiteFormatada).setDate(new Date(dataLimiteFormatada).getDate() + 1));
    var hoje = new Date();

    if (dataLimite >= hoje)
      return moment(hoje.setDate(hoje.getDate() - 1)).format("YYYY-MM-DD");

    return dataLimiteFormatada;
  }, [movimentacaoFiltro.dataInicial])

  const getDataInicial = useCallback(async (tipoOperacao: ETipoOperacaoMovimentacao) => {
    try {

      const res = await getPeriodoInicial(tipoOperacao);

      if (res.erro)
        throw res.erro;

      if (!res.resultado?.data)
        return;

      var dataFinal: any;
      dataFinal = dataMaxima(res.resultado?.data.dataUltimaMovimentacao);

      if (res.resultado?.data.temMovimentacao === true) {
        setMovimentacaoFiltro(
          {
            ...movimentacaoFiltro,
            dataInicial: res.resultado?.data.dataUltimaMovimentacao,
            tipoOperacao: tipoOperacao,
            dataFinal: dataFinal
          });

        if (resetarFiltro === true) {

          setResetarFiltro(false);

          const novoFiltro = {
            ...movimentacaoFiltro,
            dataInicial: res.resultado?.data.dataUltimaMovimentacao,
            tipoOperacao: tipoOperacao,
            dataFinal: dataFinal,
          };

          buscarMovimentacoes(novoFiltro);

        }

        setPeriodoInicial(res.resultado?.data.periodoInicial);
        setPeriodoFinal(res.resultado?.data.periodoFinal);
      }

      setTemMovimentacao(res.resultado?.data.temMovimentacao);

    } catch (e: any) {
      if (axios.isCancel(e))
        return;

      console.error("error", e.message);
      toast.error('O processo resultou em um erro interno da API');
    }

  }, [buscarMovimentacoes, dataMaxima, getPeriodoInicial, movimentacaoFiltro, resetarFiltro, setResetarFiltro]);

  const selecionarTipoOperacao = useCallback((tipoOperacaoSelecionado: ETipoOperacaoMovimentacao) => {
    setMovimentacaoFiltro({ ...movimentacaoFiltro, tipoOperacao: tipoOperacaoSelecionado });
    setTipoOperacaoSelecionado(tipoOperacaoSelecionado);
    limparCardMovimentacoes();
    getDataInicial(tipoOperacaoSelecionado);
  }, [getDataInicial, limparCardMovimentacoes, movimentacaoFiltro])

  useEffect(() => {
    selecionarTipoOperacao(ETipoOperacaoMovimentacao.Dispensacao);
  }, [])

  useEffect(() => {
    if (resetarFiltro) {
      selecionarTipoOperacao(tipoOperacaoSelecionado);
    }
  }, [resetarFiltro])

  const isLoading = () => carregandoPeriodoInicial


  const dataMinima = () => {
    var dataInicial = moment(movimentacaoFiltro.dataInicial).format("YYYY-MM-DDT00:00:00");
    return moment(new Date(dataInicial)).format("YYYY-MM-DD");
  }

  const validarPeriodo = () => {
    var dataInicial = moment(movimentacaoFiltro.dataInicial).format("DD/MM/YYYY");
    var dataFinal = moment(movimentacaoFiltro.dataFinal).format("DD/MM/YYYY");
    var periodoInicialUltimaMovimentacao = moment(periodoInicial).format("DD/MM/YYYY");
    var periodoFinalUltimaMovimentacao = moment(periodoFinal).format("DD/MM/YYYY");

    if (dataInicial <= periodoFinalUltimaMovimentacao && dataInicial >= periodoInicialUltimaMovimentacao)
      return validarConflitoPeriodo(true);

    if (dataFinal >= periodoInicialUltimaMovimentacao && dataFinal <= periodoFinalUltimaMovimentacao)
      return validarConflitoPeriodo(true);

    return validarConflitoPeriodo(false);
  }

  const possuiDataInicial = () =>
    movimentacaoFiltro.dataInicial !== null
    && movimentacaoFiltro.dataInicial?.toString().length < 1;

  return (
    <>

      {isLoading() && <CircularLoading tipo="FULLSIZED" />}

      <DefaultCard className={styles.card}>

        <div className={styles.rules}>
          <span> - Intervalo máximo é de 7 dias</span>
          <span> - Data Inicial e Final não pode ser maior ou igual a data de hoje</span>
        </div>

        <div className={`flex-column w-100 ${styles.inputContainer}`}>
          <div className={classNames(styles.containerInput, temMovimentacao ? styles.margin : "")} >
            <div className={styles.inputAlignOperacao} >
              <SelectSaurus
                className={`h-100 w-100 ${styles.inputStyle}`}
                label="Tipo da Operação"
                value={tipoOperacaoSelecionado}
                variant="outlined"
                size="small"
                conteudo={listaTipoOperacao}
                onChange={(e) => selecionarTipoOperacao(parseInt(e.target?.value) as ETipoOperacaoMovimentacao)}
              />
            </div>

            <div className={styles.inputDateContainer}>
              <div className={styles.inputDateAlign}>
                <div className={styles.inputAlign}>
                  <TextFieldSaurus
                    className={`h-80 w-100 ${styles.inputStyle}`}
                    labelCustom="Data Inicial"
                    value={movimentacaoFiltro.dataInicial ? new Date(movimentacaoFiltro.dataInicial).toISOString().slice(0, 10) : ''}
                    maxDate={moment(new Date().setDate(new Date().getDate() - 1)).format("YYYY-MM-DD")}
                    tipo="DATA"
                    size="small"
                    variant="outlined"
                    showStartAdornment={true}
                    error={errors.some(x => x === 'dataInicial')}
                    helperText={errors.some(x => x === 'dataInicial') && 'Data Inicial é obrigatório'}
                    onChange={(e: any) =>
                      setMovimentacaoFiltro(
                        {
                          ...movimentacaoFiltro,
                          dataInicial: e.target.value,
                          dataFinal: null
                        })
                    }
                  />
                </div>
                <div className={styles.inputAlign}>
                  <TextFieldSaurus
                    className={`h-80 w-100 ${styles.inputStyle}`}
                    labelCustom="Data Final"
                    value={movimentacaoFiltro.dataFinal}
                    minDate={!possuiDataInicial() ? dataMinima() : ""}
                    maxDate={!possuiDataInicial() ? dataMaxima() : ""}
                    tipo="DATA"
                    size="small"
                    variant="outlined"
                    disabled={possuiDataInicial()}
                    showStartAdornment={true}
                    error={errors.some(x => x === 'dataFinal')}
                    helperText={errors.some(x => x === 'dataFinal') && 'Data Final é obrigatório'}
                    onChange={(e: any) =>
                      setMovimentacaoFiltro({ ...movimentacaoFiltro, dataFinal: e.target.value })
                    }
                    onBlur={(e: any) => {
                      verificarPeriodoPermitido();
                    }}
                  />
                </div>
              </div>

              {temMovimentacao &&
                <span className={styles.fontBold}>
                  Período da última movimentação: {moment(periodoInicial).format("DD/MM/YYYY")} - {moment(periodoFinal).format("DD/MM/YYYY")}
                </span>
              }
            </div>

          </div>

          <div className={
            classNames(
              styles.buttonStyle,
              styles.darkButton,
              styles.containerButton)}>

            <Button onClick={() => {

              if (!verificarPeriodoPermitido())
                return;

              buscarMovimentacao()
            }
            } variant="text" className={`h-100 w-100 ${styles.buttonHeight}`}>
              <div className={`flex-row ${styles.buttonLabel} ${styles.whiteLabel}`}>
                Buscar
              </div>
            </Button>
          </div>

        </div>
      </DefaultCard >

    </>
  );
});
import { Button, Typography } from "@material-ui/core";
import classNames from "classnames";
import { useState } from "react";
import ConfirmaIcon from "../../../../../assets/img/icon-confirma.svg";
import { AutocompleteMedicamento } from "../../../../components/autocompletes/autocomplete-medicamentos";
import { DefaultCard } from "../../../../components/cards/components";
import { TextFieldSaurus } from "../../../../components/controles/inputs";
import { useStyles } from "./compras-styles";
import { CompraAvulsaMedicamentoLotesProp, CompraMedicamentoItem, EnumUnidadeComercial } from "./models/compras-cadastro.model";
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from "yup";
import { Controller, useForm } from "react-hook-form";
import { useToastSaurus } from '../../../../../services/app';
import { SelectSaurus } from "../../../../components/controles/selects/select-saurus/select-saurus";
import { KeyValueModel } from "../../../../../model";
import { toNumber } from "lodash";

export const NovaCompraAvulsaMedicamentosCard = (({ adicionarNovoMedicamento }: CompraAvulsaMedicamentoLotesProp) => {
  const styles = useStyles();
  const [, setAtt] = useState(false)
  const { showToastPersonalizado } = useToastSaurus();

  const schema = yup
    .object()
    .shape({
      quantidade: yup.number()
        .required("Itens é obrigatório!")
        .min(1, 'Itens é obrigatório!')
        .typeError("Itens é obrigatório!"),
      unidadeComercialId: yup.number()
        .required("Unidade é obrigatória!").typeError('Unidade é obrigatória!'),
      medicamentoNome: yup.string().required('Selecione o Medicamento!')
    });

  const {
    formState: { errors },
    clearErrors,
    setValue,
    reset,
    getValues,
    handleSubmit,
    control
  } = useForm<CompraMedicamentoItem>({
    criteriaMode: 'all',
    mode: 'onChange',
    resolver: yupResolver(schema),
    defaultValues: new CompraMedicamentoItem(),
  });

  const adicionar = async (values: CompraMedicamentoItem) => {
    adicionarNovoMedicamento(values);
    reset(new CompraMedicamentoItem())
    setAtt(prev => !prev)
  }

  const handleMedicamentoAutocomplete = (medicamento: any) => {
    if (!medicamento.ms)
      return showToastPersonalizado('error', 'Este medicamento não pode ser selecionado, pois não possui o código da anvisa!', 3000, 'top-center');

    clearErrors("quantidade");
    setValue('medicamentoId', medicamento.id)
    setValue('medicamentoNome', medicamento.descricao)
    setValue('codigoAnvisa', medicamento.ms)
    setValue('vendaControlada', medicamento.vendaControlada)
    setValue('codigoEan', medicamento?.value?.codigoEan ?? '')
    setValue('laboratorioId', medicamento?.value?.laboratorioId ?? '')
    setValue('unidadeComercialId', medicamento?.unidadeComercialId ?? getValues('unidadeComercialId'))
    setValue('quantidade', medicamento?.quantidade ?? getValues('quantidade'))

    setAtt(prev => !prev)
  };

  const buscarValoresUnidadeComercial = (): KeyValueModel[] => {
    const valores: KeyValueModel[] = [];
    for (let key in EnumUnidadeComercial) {
      if (isNaN(Number(key))) {
        valores.push(new KeyValueModel(EnumUnidadeComercial[key], key));
      }
    }
    return valores;
  };

  return (
    <>
      <DefaultCard>
        <form onSubmit={handleSubmit(adicionar)}>
          <div className={classNames('flex-row', 'h-100', 'w-100', styles.infoContainerCompraAvulsa, styles.divCardMed)}>
            <div className={`flex-column w-100 ${styles.infoContainer}`}>
              <div className="flex-row w-100">
                <AutocompleteMedicamento
                  value={getValues('codigoAnvisa') ? `${getValues('medicamentoNome')} - ${getValues('codigoAnvisa')}` : ''}
                  label="Buscar Medicamento"
                  className={`h-50 w-100 ${styles.inputMedStyle}`}
                  onChange={(e: any) => handleMedicamentoAutocomplete(e.value)}
                  error={Boolean(errors.medicamentoNome)}
                  helperText={
                    errors.medicamentoNome && errors.medicamentoNome?.message
                      ? errors.medicamentoNome?.message
                      : undefined
                  }
                />
              </div>

              <div className={`flex-row w-100 ${styles.itensDiv}`}>

                <div className={`flex-column ${styles.widthInput}`}>
                  <Typography className={styles.labelFont}>
                    Código
                  </Typography>
                  <Typography className={styles.contentFont}>
                    {getValues('codigoAnvisa')}
                  </Typography>
                </div>

                <div className={`flex-column ${styles.widthInput}`}>
                  <Controller
                    control={control}
                    name="unidadeComercialId"
                    render={({ field }) => (
                      <SelectSaurus
                        label="Undidade"
                        variant="outlined"
                        size="small"
                        conteudo={buscarValoresUnidadeComercial()}
                        className={`h-100 w-100 ${styles.inputMedStyle}`}
                        error={Boolean(errors.unidadeComercialId)}
                        helperText={
                          errors.unidadeComercialId && errors.unidadeComercialId?.message
                            ? errors.unidadeComercialId?.message
                            : undefined
                        }
                        {...field}
                        value={getValues('unidadeComercialId')}
                        onChange={(e: any) => {
                          const valor = buscarValoresUnidadeComercial().find(x => x.Key === e.target.value)
                          if (!valor) return
                          setValue('unidadeComercialId', valor.Key)
                        }}
                      />
                    )}
                  />
                </div>

                <div className={`flex-column ${styles.widthInputItens}`}>
                  <Controller
                    control={control}
                    name="quantidade"
                    render={({ field }) => (
                      <TextFieldSaurus className={`h-100 w-100 ${styles.inputMedStyle}`}
                        labelCustom="Itens"
                        variant="outlined"
                        size="small"
                        tipo='NUMERO'
                        error={Boolean(errors.quantidade)}
                        helperText={
                          errors.quantidade
                            ? errors.quantidade?.message
                            : undefined
                        }
                        {...field}
                        onChange={(e) => setValue('quantidade', toNumber(e.target.value))}
                        maxLength={7}
                      />
                    )}
                  />
                </div>
              </div>

              <div className={`flex-row w-100 ${styles.containerButtonAdd}`}>
                <div className={classNames(styles.addButtonStyle, styles.darkButton)}>
                  <Button type="submit" variant="text" className="h-100 w-100">
                    <div className={`flex-row ${styles.buttonLabel} ${styles.whiteLabel}`}>
                      <img className={styles.addButtonIcon} src={ConfirmaIcon} alt="" />
                    </div>
                  </Button>
                </div>
              </div>

            </div>


          </div>
        </form>
      </DefaultCard>
    </>
  );
});